import { FormWithRedux } from '../../FormWithRedux';
import { ACCBISTOrientationCheck4Form } from './ACCBIST-OrientationCheck4Form';
import { ACCBISTRecollectionCheck4Form } from './ACCBIST-RecollectionCheck4Form';
import { ACCBISTRecollectionFailureCheck4Form } from './ACCBIST-RecollectionFailureCheck4Form';
import { ACCBISTNeuroObsForm } from './ACCBIST-NeuroObsForm';
import moment from 'moment';

import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { calcAWPTA } from '../../../helpers/AWPTACalc';

export class ACCBISTAWPTA4Form extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('awptaCheck4Subform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch(identifier) {
            case 'orientationCheck4Subform':
                return new ACCBISTOrientationCheck4Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'recollectionCheck4Subform':
                return new ACCBISTRecollectionCheck4Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'recollectionFailureCheck4Subform':
                return new ACCBISTRecollectionFailureCheck4Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'gcsSubform4':
                return new ACCBISTNeuroObsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater, "4");
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        return true;
    }

    getValueType(identifier) {
        return 'date';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'awptaCheck4DateTime') {
            let self = this;

            let timeConflict = new FormValidation('AbnormalitiesPresentConflict', function () {
                let value = self.getValue(identifier);
                let check1Value = self.getValue('awptaCheck3DateTime');
                if (value != null && value!= undefined && check1Value != null && check1Value != undefined) {
                    let firstCheck = moment(new Date(check1Value));
                    let secondCheck = moment(new Date(value));
                    var duration = moment.duration(secondCheck.diff(firstCheck));
                    var hours = duration.asHours();

                    if (hours < 1) {
                        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Current check should be at least 1 hour after the previous.')];
                    }
                    else {
                        return [];
                    }
                
                }
                else {
                    return [];
                }
            });
            return [timeConflict];
        }
        
        return [];
    }

    detailForIdentifier(prefix, value) {
        if(value.identifier == 'gcsSubform4') {
            return null;
        }
        
        return super.detailForIdentifier(prefix, value);
    }

    htmlForIdentifier(prefix, value) {
        if(value.identifier == 'gcsSubform4') {
            return null;
        }
        else {
            return super.htmlForIdentifier(prefix, value);
        }
    }

    shouldShowInSidebar() {
        const rawData = this.parent._workflow?.accbist01?.rawData;
        if(rawData) {
            const recollectionCheck = [{check: 'recollectionCheck3', recall: 'recollectionFailureCheck3'}];
            const totalAWPTA = calcAWPTA(rawData, recollectionCheck, rawData.totalGCS).totalAWPTA;

            if(totalAWPTA !== 0 && totalAWPTA !== null && totalAWPTA !== undefined && totalAWPTA < 18) {
                return true;
            }
        }

        return false;
    }
}