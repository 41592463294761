// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormButton } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { FormTextField } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormTextField';
import { exportToEmail, resetState } from '../../state/actions/ExportToEmailActions';
import { Loading } from '../../shared/Loading';
import { FormLabel } from '../../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { incrementWorkFlow, markWorkFlowAsExported } from '../../state/actions/WorkflowResponseActions';
import { ENVIRONMENT } from '../../../config/config';

// COMPONENT
class EmailOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: this.props.userInfo !== undefined ? this.props.userInfo.Email : null,
            name: this.props.userInfo !== undefined ? this.props.userInfo.Name : null,
            isReadyForSubmission: false
        };

        this.cancelAction = this.cancelAction.bind(this);
        this.performExport = this.performExport.bind(this);
        this.dismiss = this.dismiss.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.setSubmissionStatus = this.setSubmissionStatus.bind(this);
        this.submit = this.submit.bind(this);
        this.preview = this.preview.bind(this);
    }

    componentDidUpdate(prevProps) {
        this.setSubmissionStatus();
        if (this.props.exportToEmailFulfilled && !prevProps.exportToEmailFulfilled) {
            this.props.markWorkFlowAsExported(this.props.pathwayWorkflow.pathwayUuid, this.props.pathwayWorkflow);
        }
    }

    componentDidMount() {
        this.setSubmissionStatus();
    }

    componentWillUnmount() {

    }

    setSubmissionStatus() {
        let isReady = false;
        if(this.state.email != null && this.state.name != null) {
            isReady = true;
        }
        
        if (this.state.isReadyForSubmission != isReady) {
            this.setState({isReadyForSubmission: isReady});
        }
    }

    cancelAction() {
        this.props.resetState();
        this.props.toggleOverlay();
    }

    submit() {
        this.performExport(false);
    }

    preview() {
        this.performExport(true);
    }

    getReferrerEmailDomain() {
        if(this.state.email !== undefined && !this.state.email.includes("@")) {
            if (this.checkIfPathwayIsAcc()) {
                return '@cdhb.health.nz';
            } else {
                return '@wayfind.health';
            }
        }
    }

    checkIfPathwayIsAcc() {
        return (this.props.pathwayId === 'accbist01');
    }

    performExport() {
        let params = {
            plainTextExport: this.props.plainTextExport,
            referrerName: this.state.name
        };

        let to; 
        let cc; 
        let bcc;

        let referrerEmail = this.state.email.split('@')[0] + this.getReferrerEmailDomain();

        switch (ENVIRONMENT) {
            case 'DEV':
                to = [referrerEmail];
                cc = [referrerEmail];
                bcc = this.checkIfPathwayIsAcc() ? ['wayfind@cdhb.health.nz'] : [];
                break;
            case 'PROD':
                to = [referrerEmail];
                cc = [];
                bcc = this.checkIfPathwayIsAcc() ? ['wayfind@cdhb.health.nz'] : [];
                break;
            case 'DEMO':
                to = [referrerEmail];
                cc = [referrerEmail];
                bcc = [];
                break;
            case 'TEST':
                to = [referrerEmail];
                cc = [referrerEmail];
                bcc = [];   
                break;
            case 'STAGING':
                to = [referrerEmail];
                cc = [referrerEmail];
                bcc = this.checkIfPathwayIsAcc() ? ['wayfind@cdhb.health.nz'] : [];
                break;
            default:
                to = [referrerEmail];
                cc = [];
                bcc = [];
                break;
        }

        var data = {
            to: to,
            cc: cc,
            bcc: bcc,
            subject: 'Wayfind Pathway Data',
            params: params
        };

        this.props.exportToEmail(data);
    }

    dismiss() {
        this.props.resetState();
        this.props.toggleOverlay();
    }

    emailChange(value) {
        this.setState({
            email: value
        });
    }

    nameChange(value) {
        this.setState({
            name: value
        });
    }

    render() {
        return (
            <div className="session-wrapper">
                <div className="session-overlay" />

                <div className="environment-manager-container">
                    <div className="environment-card open" ref={sessionCardNode => this.sessionCardNode = sessionCardNode}>

                        <div className="referral-card-header">
                            <header className={'session-title'}>{'Send Summary Email'}</header>
                           

                            <div className="card-overlay-exit-button-referral-container">
                                {<FormButton title={''} image={<img className={'session-card-exit'} src={require('../../../images/cross-blue.png')} />} backgroundColor={FormsStyles.backgroundColor} enabled={true} isSelected={false} onPress={this.dismiss} />}
                            </div>
                        </div>

                        {!this.props.exportToEmailPending && (this.props.exportToEmailFulfilled || this.props.exportToEmailFailed) &&
                            <div className="export-status-container">
                                <div><FormLabel value={this.props.exportToEmailFulfilled ? 'Email Sent' : 'Error, failed to send. Please try again.'} textColor={this.props.exportToEmailFulfilled ? FormsStyles.primaryTint : 'red'} fontSize={this.props.exportToEmailFulfilled ? 1 : null} /></div>
                            </div>
                        }

                        <div className="session-body">
                            {!this.props.exportToEmailPending && !this.props.exportToEmailFulfilled &&
                                <div>
                                    <div className="referral-details-section">

                                        <h3 className="referral-section-title">Your Details</h3>
                                        
                                        <div className="referral-section-subtitle">
                                            Name
                                            <FormTextField className="name-input" value={this.state.name} spellCheck={false} onKeyPress={this.onKeyPress} inputType={'text'} alignment={'right'} onChange={this.nameChange} shouldDelayResponse={false} placeholder={'Name'} width={'100%'} />
                                        </div>

                                        <div className="referral-section-subtitle">
                                            Email
                                            <FormTextField className="email-input" value={this.state.email} spellCheck={false} onKeyPress={this.onKeyPress} inputType={'email'} unitText={this.getReferrerEmailDomain()} alignment={'right'} onChange={this.emailChange} shouldDelayResponse={false} placeholder={'Email'} width={'100%'} />
                                        </div>
                                    </div>
                                </div>

                            }
                            {this.props.exportToEmailPending && <Loading />}
                            {!this.props.exportToEmailPending &&
                                <div className="export-overlay-buttons-container">
                                    {!this.props.exportToEmailFulfilled && 
                                    <div>
                                        <FormButton title={'Submit'} enabled={this.state.isReadyForSubmission} isSelected={false} onPress={this.submit} />
                                    </div>}
                                    {this.props.exportToEmailFulfilled && <FormButton title={'Okay'} enabled={true} isSelected={false} onPress={this.cancelAction} />}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EmailOverlay.propTypes = {
    exportToEmail: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
    exportToEmailPending: PropTypes.bool.isRequired,
    exportToEmailFulfilled: PropTypes.bool.isRequired,
    exportToEmailFailed: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    pathwayWorkflow: PropTypes.object.isRequired,
    incrementWorkFlow: PropTypes.func.isRequired,
    markWorkFlowAsExported: PropTypes.func.isRequired,
    pathwayId: PropTypes.string.isRequired,
    toggleOverlay: PropTypes.func.isRequired,
    plainTextExport: PropTypes.string.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { exportToEmailPending, exportToEmailFulfilled, exportToEmailFailed } = state.exportToEmailReducer;
    return { exportToEmailPending, exportToEmailFulfilled, exportToEmailFailed };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ exportToEmail, resetState, incrementWorkFlow, markWorkFlowAsExported }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(EmailOverlay);

// EXPORT COMPONENT

export { hoc as EmailOverlay };