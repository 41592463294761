// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Base64 } from "../../helpers/Base64Encoder";
import { PathwayReferenceHelper } from "../../forms/PathwayReferenceHelper";
import { ReferenceDataLoader } from "../../forms/ReferenceDataLoader";
import { ENVIRONMENT, PILOT_PATHWAYS } from "../../config/config";
import { FormsStyles } from "../../waferJS/FormsUI/FormsUI";
import { Chevron } from "../shared/Chevron";
import {
  getPathwayIdFromURL,
  QuidelOrthoPathWay,
  QuidelOrthoPathWay2
} from "../../helpers/URLHelper";
import { withRouter } from "../routers/withRouter";
import WayfindBackground from "../../images/Wayfind-BG.svg";
import QuidelOrthoBackground from "../../images/Quidel-BG.svg";
import QuidelOrthoLogo from "../../images/Quidel-logo.svg";
import HeaderUserProfile from "../Common/HeaderUserProfile";

// COMPONENT
class Header extends React.Component {
  constructor(props) {
    super(props);

    let environmentFormName;

    switch (ENVIRONMENT) {
      case "DEV":
        environmentFormName = "devInformationForm";
        break;
      case "PROD":
        if (PILOT_PATHWAYS.includes(getPathwayIdFromURL())) {
          environmentFormName = "pilotInformationForm";
        } else {
          environmentFormName = "pilotInformationForm";
        }
        break;
      case "DEMO":
        environmentFormName = "demoInformationForm";
        break;
      case "TEST":
        environmentFormName = "testInformationForm";
        break;
      case "STAGING":
        environmentFormName = "stagingInformationForm";
        break;
      default:
        environmentFormName = "pilotInformationForm";
        break;
    }

    this.state = {
      collapsed: props.collapsed,
      menuVisible: false,
      environmentForm: props.formLoader.form(
        "environmentInfoForm",
        environmentFormName
      ),
    };

    this.toggleBurger = this.toggleBurger.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.pushToPDF = this.pushToPDF.bind(this);
    this.pushToWayfind = this.pushToWayfind.bind(this);
    this.pushToResults = this.pushToResults.bind(this);
    this.openFeedbackURL = this.openFeedbackURL.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.collapsed != prevProps.collapsed &&
      this.state.collapsed != this.props.collapsed
    ) {
      this.setState({ collapsed: this.props.collapsed });
    }
  }

  toggleBurger() {
    if (this.state.menuVisible) {
      this.setState({ menuVisible: false });
    }

    if (this.props.collapsed) {
      this.props.showSideBar();
    } else {
      this.props.hideSideBar();
    }
  }

  toggleMenu() {
    this.setState({ menuVisible: !this.state.menuVisible });
    this.props.hideSideBar();
  }

  handleLogout() {
    this.toggleBurger();
    this.props.logout();
  }

  handleClick(e) {
    if (
      this.state.menuVisible &&
      (this.menuNode == null || !this.menuNode.contains(e.target)) &&
      (this.menuButtonNode == null || !this.menuButtonNode.contains(e.target))
    ) {
      this.setState({ menuVisible: false });
    } else if (
      (this.homeButtonNode.contains(e.target) && !this.props.isExtension) ||
      (this.homeTitleNode != null &&
        this.homeTitleNode.contains(e.target) &&
        !this.props.isExtension)
    ) {
      // Back to pathway selection page
      this.props.navigate("/");
    }
  }

  pushToPDF() {
    this.setState({ menuVisible: false });
    this.props.navigate("/" + this.props.pathwayId + "/summary");
  }

  pushToWayfind() {
    let dataString = JSON.stringify(this.props.workflows[this.props.pathwayId]);
    let dataObjectBase64 = Base64.encode(dataString);
    window.open(
      window.location.origin +
        "/" +
        this.props.pathwayId +
        "/handover?data=" +
        dataObjectBase64,
      "_blank"
    );
  }

  pushToResults() {
    this.setState({ menuVisible: false });
    this.props.navigate(
      "/" + this.props.pathwayId + "/resultsForm?isExtension=true"
    );
  }

  environmentExtension() {
    if (ENVIRONMENT == "PROD") {
      if (PILOT_PATHWAYS.includes(this.props.pathwayId)) {
        return "PILOT";
      }
      return null;
    }
    return ENVIRONMENT;
  }

  environmentTitle() {
    if (ENVIRONMENT == "PROD") {
      if (PILOT_PATHWAYS.includes(this.props.pathwayId)) {
        return "Pilot Information";
      } else {
        return null;
      }
    } else if (ENVIRONMENT == "DEMO") {
      return "Demo Information";
    } else if (ENVIRONMENT == "TEST") {
      return "TEST Environment";
    } else if (ENVIRONMENT == "Staging") {
      return "Staging Environment";
    } else if (ENVIRONMENT == "DEV") {
      return "DEV Environment";
    }

    return null;
  }

  openFeedbackURL() {
    window.open(
      "https://otago.au1.qualtrics.com/jfe/form/SV_bJYbLw7JrdwvZzw",
      "_blank",
      "noopener,noreferrer"
    );
  }

  openUpdateWorkforceAccount() {
    const params = new URLSearchParams();
    params.append("redirecturl", window.location.origin + "/auth");
    params.append("clientid", process.env.REACT_APP_CLIENT_ID ?? "");
    params.append("levelrequired", "3");

    const url = `${
      process.env.REACT_APP_HEALTH_NZ_UPDATE_ACCOUNT_URL
    }?${params.toString()}`;
    window.open(url, "_self");
  }

  getPersonIcon() {
    let style = {
      fill: FormsStyles.white,
    };

    let containerStyle = {
      paddingRight: "0.2em",
      marginTop: "0.4em",
    };

    return (
      <div style={containerStyle}>
        <svg width="20px" height="20px" viewBox="0 0 30 30" style={style}>
          <path
            d="M23.9922284,23.940541 C24.0266901,24.206187 23.9454591,24.4749584 23.7700739,24.6780995 C23.5928425,24.8824907 23.3380724,25 23.0703792,25 L0.929383194,25 C0.662305391,25 0.40753532,24.8824907 0.230303967,24.6780995 C0.0549187734,24.4749584 -0.0263122635,24.206187 0.00753400187,23.940541 C0.400150681,20.8846736 3.18169831,19.2395432 5.41616721,17.9175633 C7.05371107,16.9493615 8.46725419,16.1130452 8.46725419,15.0623369 C8.46725419,13.2790705 7.39894298,12.3383709 7.13863443,12.1358548 C6.35770878,12.0039693 5.94970743,10.5094758 5.94970743,9.69191098 C5.94970743,9.14249241 6.10909257,8.86684555 6.28324699,8.72995969 C5.71770667,6.85668613 5.72816824,4.26085527 7.01186478,2.42258448 C8.0155604,0.985595522 9.67279663,0.222409997 11.8032652,0.214909402 C13.3423472,-0.274504411 15.018045,0.0855241409 16.1866643,1.1568591 C17.1454366,2.03630385 18.5983645,4.17709863 17.7115924,8.70995811 C17.895593,8.83934337 18.0728243,9.11374013 18.0728243,9.69191098 C18.0728243,10.5107259 17.6642076,12.0064695 16.8808204,12.1358548 C16.6131272,12.339621 15.5331236,13.2678196 15.5331236,15.0623369 C15.5331236,16.1130452 16.9466668,16.9493615 18.5835952,17.9175633 C20.8186795,19.2395432 23.5996118,20.8846736 23.9922284,23.940541"
            id="Path"
          ></path>
        </svg>
      </div>
    );
  }

  render() {
    let userInformation = this.props.userName;
    return (
      <div
        className={
          "header-container " + (this.props.collapseHeader ? "docked" : "")
        }
      >
        <div className={(this.props.pathwayId === QuidelOrthoPathWay || this.props.pathwayId === QuidelOrthoPathWay2) ? "header-content-wrapper-quidel-ortho": "header-content-wrapper"}>
          <div className="header-content-container">
            <div className="header-left-container">
              {!this.props.isExtension && (
                <div className="left-hamburger-container">
                  {this.state.collapsed &&
                    this.props.showResultsBadge[this.props.pathwayId] != null &&
                    this.props.showResultsBadge[this.props.pathwayId] ==
                      true && <div className="header-notification-badge" />}
                  <div
                    className="hamburger-container"
                    onClick={() => this.toggleBurger()}
                    ref={this.props.hamburgerNode}
                  >
                    <div
                      className={
                        "bar1 " + (this.state.collapsed ? "" : "change")
                      }
                    ></div>
                    <div
                      className={
                        "bar2 " + (this.state.collapsed ? "" : "change")
                      }
                    ></div>
                    <div
                      className={
                        "bar3 " + (this.state.collapsed ? "" : "change")
                      }
                    ></div>
                  </div>
                </div>
              )}

              { (this.props.pathwayId === QuidelOrthoPathWay || this.props.pathwayId === QuidelOrthoPathWay2) ? (
                <div className="home-button" title="Return to Overview">
                  <img
                    className={
                      "quidel-header-logo " +
                      (this.props.collapseHeader ? "docked" : "")
                    }
                    src={QuidelOrthoLogo}
                    ref={(homeButtonNode) =>
                      (this.homeButtonNode = homeButtonNode)
                    }
                  />
                </div>
              ) : (
                <div className="home-button" title="Return to Overview">
                  <img
                    className={
                      "header-logo " +
                      (this.props.collapseHeader ? "docked" : "")
                    }
                    src={require("../../images/logo-white.png")}
                    ref={(homeButtonNode) =>
                      (this.homeButtonNode = homeButtonNode)
                    }
                  />
                  <label className="environment-tag">
                    {this.environmentExtension()}
                  </label>
                  {this.environmentExtension() != null && (
                    <div
                      className="environemnt-button-container"
                      title={this.environmentTitle()}
                    >
                      {" "}
                      <button
                        className="environemnt-button"
                        onClick={this.props.toggleEnvironmentOverlay}
                      >
                        <img
                          className={"info-button-graphic"}
                          src={require("../../images/info.png")}
                        />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="header-center-container">
              <div
                className={
                  "header-main-title " +
                  (this.props.collapseHeader ? "docked" : "")
                }
              >
                {PathwayReferenceHelper.reference(
                  "title",
                  ReferenceDataLoader.referenceData(this.props.pathwayId)
                )}
              </div>
              {this.environmentExtension() != null && (
                <div className="environment-tag-container-mobile">
                  <label className="environment-tag-mobile">
                    {this.environmentExtension()}
                  </label>
                  <div className="environment-button-container-mobile">
                    {" "}
                    <button
                      className="environemnt-button-mobile"
                      onClick={this.props.toggleEnvironmentOverlay}
                    >
                      <img
                        className={"info-button-graphic"}
                        src={require("../../images/info.png")}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="header-right-container">
              <div
                ref={(menuButtonNode) => (this.menuButtonNode = menuButtonNode)}
                className="header-menu-button-container"
              >
                <button
                  onClick={this.toggleMenu}
                  className="header-menu-button"
                  tabIndex="-1"
                >
                  {userInformation != null && (
                    <div className="header-menu-button-username-container">
                      {this.getPersonIcon()}
                      <div className="header-menu-button-username">
                        {userInformation}
                      </div>
                    </div>
                  )}
                  <div className="header-menu-button-image-container">
                    <Chevron
                      angle={
                        this.state.menuVisible
                          ? "rotate(180deg)"
                          : "rotate(0deg)"
                      }
                      color={FormsStyles.white}
                    />
                  </div>
                </button>
              </div>
              {this.state.menuVisible && (
                <div
                  ref={(menuNode) => (this.menuNode = menuNode)}
                  className={
                    "header-menu-container " +
                    (this.props.collapseHeader ? "docked" : "")
                  }
                >
                  <HeaderUserProfile
                    collapseHeader={this.props.collapseHeader}
                    userName={this.props.userName}
                    userEmail={this.props.userEmail}
                    userHPINumber={this.props.userHPINumber}
                    isLowerAccess={this.props.isLowerAccess}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={
            "header-background-container " +
            (this.props.collapseHeader ? "docked" : "")
          }
        >
          <div className="header-background-image">
            { (this.props.pathwayId === QuidelOrthoPathWay || this.props.pathwayId === QuidelOrthoPathWay2) ? (
              <img
                className=""
                alt="Quidel background"
                src={QuidelOrthoBackground}
              />
            ) : (
              <img
                className=""
                alt="Wayfind background"
                src={WayfindBackground}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  navigate: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  showSideBar: PropTypes.func.isRequired,
  toggleSessionManager: PropTypes.func.isRequired,
  isExtension: PropTypes.bool.isRequired,
  workflows: PropTypes.object.isRequired,
  hideSideBar: PropTypes.func.isRequired,
  hamburgerNode: PropTypes.func.isRequired,
  showResultsBadge: PropTypes.object.isRequired,
  collapseHeader: PropTypes.bool.isRequired,
  pathwayId: PropTypes.string.isRequired,
  toggleEnvironmentOverlay: PropTypes.func.isRequired,
  isOverlayVisible: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  pathwayNeedsAuth: PropTypes.bool.isRequired,
  formLoader: PropTypes.object.isRequired,
};

// // CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
  const { workflows } = state.workflowReducer;
  const { showResultsBadge } = state.notificationsReducer;

  return { workflows, showResultsBadge };
};

const hoc = withRouter(connect(mapStateToProps)(Header));

// EXPORT COMPONENT
export { hoc as Header };
