
import { BloodPressureTextFormDetail } from './Subclasses/BloodPressureTextFormDetail';
import { DecimalNumberFormDetail } from './Subclasses/DecimalNumberFormDetail';
import { TroponinFormDetail } from '../../forms/edChestPain01/Custom Form Details/Components/TroponinFormDetail';
import { QuidelOrthoTroponinFormDetail } from '../../forms/edChestPain01/Custom Form Details/Components/QuidelOrthoTroponinFormDetail';
import { QuidelOrtho2TroponinFormDetail } from '../../forms/edChestPain01/Custom Form Details/Components/QuidelOrtho2TroponinFormDetail';
import { WrappingTextFormDetail } from './Subclasses/WrappingTextFormDetail';
import { ExplicitSelectionFormDetail } from './Subclasses/ExplicitSelectionFormDetail';
import { CompletionItemFormDetail } from './Subclasses/CompletionItemFormDetail';
import { SegmentedControlFormDetail } from './Subclasses/SegmentedControlFormDetail';
import { SubformFormDetail } from './Subclasses/SubformFormDetail';
import { WebLinkFormDetail } from './Subclasses/WebLinkFormDetail';
import { CalculationFormDetail } from './Subclasses/CalculationFormDetail';
import { RadioFormDetail } from './Subclasses/RadioFormDetail';
import { DateAndTimeFormDetail } from './Subclasses/DateAndTimeFormDetail';
import { FormsContentType } from '../FormsUI/FormsUI';
import { FormSummaryFormDetail } from '../Form To FormsUI/Subclasses/FormSummaryFormDetail';
import { DateOfBirthFormDetail } from './Subclasses/DateOfBirthFormDetail';
import { BadgeFormDetail } from './Subclasses/BadgeFormDetail';
import { ActionFormDetail } from './Subclasses/ActionFormDetail';
import { SingleLineTextFormDetail } from './Subclasses/SingleLineTextFormDetail';
import React from 'react';
import PropTypes from 'prop-types';
import { PersonGraphFormDetail } from '../../forms/edChestPain01/Custom Form Details/Components/PersonGraphFormDetail';
import { PlainFormDetail } from './Subclasses/PlainFormDetail';
import { RedFlagFormDetail } from '../../forms/edChestPain01/Custom Form Details/Components/RedFlagFormDetail';
import { DateFormDetail } from './Subclasses/DateFormDetail';
import { ExportFormDetail } from './Subclasses/ExportFormDetail';
import { NoToGroupFormDetail } from './Subclasses/NoToGroupFormDetail';
import { ScaleFormDetail } from './Subclasses/ScaleFormDetail';
import { ScaleKeyFormDetail } from './Subclasses/ScaleKeyFormDetail';
import { DropDownFormDetail } from './Subclasses/DropDownFormDetail';
import { PictureGridFormDetail } from './Subclasses/PictureGridFormDetail';
import { PictureGridCheck1FormDetail } from './Subclasses/PictureGridCheck1FormDetail';
import { CalloutFormDetail } from './Subclasses/CalloutFormDetail';
import { ACCReferralSubmissionFormDetail } from './Subclasses/ACCReferralSubmissionFormDetail';
import NHINumberFormDetail from './Subclasses/NHINumberFormDetail';

export class FormDetailGenerator extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            rawValue: this.props.rawValue
        };
    }

    componentDidUpdate(prevProps){
        if(this.props.rawValue !== prevProps.rawValue){
            this.setState({rawValue: this.props.rawValue});
        }
    }

    render() {
        switch (this.props.formDetail.type) {
            case FormsContentType.ItemType.control:
                switch (this.props.formDetail.controlType) {
                    case FormsContentType.ControlType.redFlag:
                        return <RedFlagFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.scaleKey:
                        return <ScaleKeyFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.scale:
                        return <ScaleFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.pictureGrid:
                        return <PictureGridFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.pictureGridCheck1:
                        return <PictureGridCheck1FormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.plain:
                        return <PlainFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.personGraph:
                        return <PersonGraphFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.radio:
                        return <RadioFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.noToAll:
                        return <NoToGroupFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} rawValue={this.state.rawValue} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.completionItem:
                        return <CompletionItemFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.textEntry:
                        switch (this.props.formDetail.controlSubtype) {
                            case FormsContentType.TextEntryType.wrappingPlainText:
                                return <WrappingTextFormDetail formDetail={this.props.formDetail} showHover={this.props.showHover} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.TextEntryType.wrappingPlainTextLarge:
                                return <WrappingTextFormDetail formDetail={this.props.formDetail} isLarge={true} showHover={this.props.showHover} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;    
                            case FormsContentType.TextEntryType.decimalNumber:
                                return <DecimalNumberFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.TextEntryType.troponin:
                                return <TroponinFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.TextEntryType.qoTroponin:
                                return <QuidelOrthoTroponinFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.TextEntryType.qoTroponin2:
                                return <QuidelOrtho2TroponinFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.TextEntryType.singleLinePlainText:
                                return <SingleLineTextFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.TextEntryType.plainTextWithNHILookup:
                                return <NHINumberFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            default:
                                console.log('unknown text entry type: ', this.props.formDetail.textEntryType);
                                return null;
                        }
                    case FormsContentType.ControlType.selection:
                        switch (this.props.formDetail.controlSubtype) {
                            case FormsContentType.SelectionType.explicit:
                                return <ExplicitSelectionFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} rawValue={this.state.rawValue} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.SelectionType.simple:
                                return <SegmentedControlFormDetail formDetail={this.props.formDetail} options={['None of the above']} isSubcell={this.props.isSubcell} rawValue={this.state.rawValue} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.SelectionType.dropdown:
                                return <DropDownFormDetail formDetail={this.props.formDetail} options={['None of the above']} isSubcell={this.props.isSubcell} rawValue={this.state.rawValue} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            default:
                                console.log('unknown selection type: ', this.props.formDetail.controlSubtype);
                                return null;
                        }
                    case FormsContentType.ControlType.weblink:
                        return <WebLinkFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.picker:
                        switch (this.props.formDetail.controlSubtype) {
                            case FormsContentType.PickerType.date:
                                return <DateFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.PickerType.dateTime:
                                return <DateAndTimeFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.PickerType.dateOfBirth:
                                return <DateOfBirthFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            case FormsContentType.PickerType.bloodPressure:
                                return <BloodPressureTextFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                            default:
                                console.log('unknown picker type: ', this.props.formDetail.controlSubtype);
                                return null;
                        }
                    case FormsContentType.ControlType.badge:
                        return <BadgeFormDetail formDetail={this.props.formDetail} showHover={this.props.showHover} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.calculation:
                        return <CalculationFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.action:
                        return <ActionFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />; 
                    case FormsContentType.ControlType.export:
                        return <ExportFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.callout:
                        return <CalloutFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    case FormsContentType.ControlType.accReferralSubmission: 
                        return <ACCReferralSubmissionFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                    default:
                        console.log('unknown control type: ', this.props.formDetail.controlType);
                        return null;
                }
            case FormsContentType.ItemType.subform:
                if (this.props.isSummary) {
                    return <FormSummaryFormDetail formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                } else {
                    return <SubformFormDetail formLoader={this.props.formLoader} formDetail={this.props.formDetail} isSubcell={this.props.isSubcell} renderErrors={this.props.renderErrors} renderSubcells={this.props.renderSubcells} errorLabels={this.props.errorLabels} hasError={this.props.hasError} />;
                }
            default:
                console.log('here at the end', this.props.formDetail.type);
        }
    }

}

FormDetailGenerator.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSummary: PropTypes.bool.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    showHover: PropTypes.bool.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    renderErrors: PropTypes.func.isRequired,
    rawValue: PropTypes.any,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
};