import wayfindAxios from "../components/Common/WayfindAxios";
import { ACC_API_ROOT } from "../config/config";
import { BistQuestionnaireResponseParam } from "./storeQuestionnaireResponse";

const getQuestionnaireResponse = async (questionnaireType: string, patientNumber: string) => {
  const params = new URLSearchParams();
  params.append("patientNumber", patientNumber);
  params.append("questionnaireType", questionnaireType);

  wayfindAxios.defaults.baseURL = ACC_API_ROOT;
  const response = await wayfindAxios.get<BistQuestionnaireResponseParam>(
    `api/SharedCare/GetQuestionnaireResponse?${params.toString()}`
  );

  return response.data;
};

export default getQuestionnaireResponse;
