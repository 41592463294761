import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { FormLoader } from "../../forms/FormLoader";
import { ACCBISTPathwayReference } from "../../forms/concussion01/ACCBIST-PathwayReference";
import getQuestionnaireResponse from "../../api/getQuestionnaireResponse";
import storeQuestionnaireResponse, {
  BistQuestionnaireResponseParam,
} from "../../api/storeQuestionnaireResponse";
import { getDisplayErrorMessage } from "../../helpers/Utils";

interface NHINumberAndResourceId {
  number: string;
  resourceId: string;
}

export interface FHIRStoreReturn {
  submitQuestionnaireResponse: (pathwayId: string) => Promise<boolean>;
  formLoader: FormLoader;
  setFormDataFromPathwayData: (
    pathwayId: string,
    pathwayData: BistQuestionnaireResponseParam
  ) => void;
}

export const useFHIRStore = (formLoader: FormLoader): FHIRStoreReturn => {
  const { pathwayId, formId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [currentFormId, setCurrentFormId] = useState<string>();
  const [currentPathwayData, setCurrentPathwayData] = useState<BistQuestionnaireResponseParam>();
  const [storedQuestionnaireResponseIds, setStoredQuestionnaireResponseIds] =
    useState<NHINumberAndResourceId[]>();

  // This custom hook works in Brain Injury Pathway only for now
  const BIST_PATHWAY_ID = "accbist01";

  useEffect(() => {
    if (pathwayId === BIST_PATHWAY_ID) {
      // Validate if the Patient Details are completed when jumping from Patient Details to any form
      if (formId !== "patientDetailsSubform" && currentFormId === "patientDetailsSubform") {
        // Check if the mandatory fields are populated
        const existIncompleteField = ACCBISTPathwayReference.data[
          "template.content"
        ].patientDetailsSubform.mandatoryFields.some((fieldId) => {
          const fieldValue = formLoader.formDetail(pathwayId, fieldId)?.editingValue;
          return !fieldValue;
        });

        if (existIncompleteField) {
          enqueueSnackbar("Please complete the Patient Details form first", {
            variant: "warning",
          });

          navigate("/" + pathwayId + "/" + currentFormId);
          return;
        }
      }
      setCurrentFormId(formId);

      // Check if the Patient already has the QuestionnaireResponse and then POST
      const pathwayParams = convertToPathwayDataFromForm(pathwayId);

      let updated = true;
      let firstLoading = false;
      if (currentPathwayData) {
        // Check if the form data is updated since previous storing request
        updated = Object.entries(pathwayParams).some(([key, value]) => {
          if (key.startsWith("recollectionCheck") || key.startsWith("recollectionFailureCheck")) {
            return JSON.stringify(value) !== JSON.stringify(currentPathwayData[key]);
          } else {
            return value !== currentPathwayData[key];
          }
        });
      } else {
        firstLoading = true;
      }

      if (updated) {
        setCurrentPathwayData(pathwayParams);

        // Skip sending request at first loading
        if (firstLoading) return;

        const nhiNumber = formLoader.formDetail(pathwayId, "patientNHI")?.editingValue;
        const patientName = formLoader.formDetail(pathwayId, "patientName")?.editingValue;
        sendQuestionnaireResponseRequest(pathwayParams, nhiNumber, patientName, false);
      }
    }
  }, [pathwayId, formId]);

  const sendQuestionnaireResponseRequest = async (
    requestParams: BistQuestionnaireResponseParam,
    nhiNumber: string,
    patientName: string,
    completed: boolean
  ) => {
    let responseId: string | undefined = storedQuestionnaireResponseIds?.find(
      (pair) => pair.number === nhiNumber
    )?.resourceId;
    if (!responseId) {
      try {
        const response = await getQuestionnaireResponse("BrainInjury", nhiNumber ?? "");
        if (response && response.id) {
          responseId = response.id;
          // Keep id in state to use it for the next call
          setStoredQuestionnaireResponseIds([
            ...(storedQuestionnaireResponseIds ?? []),
            { number: nhiNumber, resourceId: response.id },
          ]);
        }
      } catch (error) {
        const errorMessage = getDisplayErrorMessage(error as AxiosError);
        console.log(errorMessage);
        enqueueSnackbar("Failed to get the in-progress form data. " + errorMessage, {
          variant: "error",
        });
      }
    }

    // Create new QuestionnaireResponse Or Update existing one
    try {
      const id = await storeQuestionnaireResponse(
        requestParams,
        "BrainInjury",
        patientName,
        nhiNumber,
        responseId,
        completed
      );

      setStoredQuestionnaireResponseIds([
        ...(storedQuestionnaireResponseIds ?? []),
        { number: nhiNumber, resourceId: id },
      ]);
      return true;
    } catch (error) {
      if (error) {
        const errorMessage = getDisplayErrorMessage(error as AxiosError);
        console.log(errorMessage);
        enqueueSnackbar("Failed to store the in-progress form data. " + errorMessage, {
          variant: "error",
        });
      }
      return false;
    }
  };

  const submitQuestionnaireResponse = async (pathwayId: string): Promise<boolean> => {
    if (pathwayId !== BIST_PATHWAY_ID) return false;
    const pathwayParams = convertToPathwayDataFromForm(pathwayId);
    const nhiNumber = formLoader.formDetail(pathwayId, "patientNHI")?.editingValue;
    const patientName = formLoader.formDetail(pathwayId, "patientName")?.editingValue;

    return await sendQuestionnaireResponseRequest(pathwayParams, nhiNumber, patientName, true);
  };

  const convertToPathwayDataFromForm = (pathwayId: string) => {
    const pathwayData: BistQuestionnaireResponseParam = {} as BistQuestionnaireResponseParam;

    Object.entries(ACCBISTPathwayReference.data["template.content"])
      .filter(
        ([key, value]) =>
          value.itemType === "control" &&
          formLoader.getForm(pathwayId, key)?.getValueType() !== null &&
          value["control.type"] !== "redflag"
      )
      .forEach(([key, value]) => {
        const propValue = formLoader.formDetail(pathwayId, key)?.editingValue;
        if (propValue !== undefined && propValue !== "") {
          // Convert picture object to a code of Questionnaire Form
          if (key.startsWith("recollectionCheck") || key.startsWith("recollectionFailureCheck")) {
            pathwayData[key] = convertPictureSelection(propValue);
          } else {
            if (key === "bloodPressure") {
              const values = propValue.toString().split("/");
              if (values.length === 2) {
                pathwayData["bloodPressureHigh"] = values[0];
                pathwayData["bloodPressureLow"] = values[1];
              }
            } else {
              pathwayData[key] = propValue;
            }
          }
        }
      });
    return pathwayData;
  };

  const setFormDataFromPathwayData = (
    pathwayId: string,
    pathwayData: BistQuestionnaireResponseParam
  ) => {
    Object.entries(ACCBISTPathwayReference.data["template.content"])
      .filter(
        ([key, value]) =>
          value.itemType === "control" &&
          formLoader.getForm(pathwayId, key)?.getValueType() !== null &&
          value["control.type"] !== "redflag"
      )
      .forEach(([key, value]) => {
        let dataValue: any = undefined;
        if (
          key === "bloodPressure" &&
          pathwayData["bloodPressureHigh"] &&
          pathwayData["bloodPressureLow"]
        ) {
          dataValue = pathwayData["bloodPressureHigh"] + "/" + pathwayData["bloodPressureLow"];
        } else {
          dataValue = pathwayData[key];
        }

        if (dataValue) {
          const formDetail = formLoader.formDetail(pathwayId, key);
          if (formDetail) {
            formDetail.form.valueUpdated(key, dataValue);
          }
        }
      });
  };

  const convertPictureSelection = (
    selectionArray: { id: number; imageName: string; alt: string; label: string; key: string }[]
  ) => {
    return selectionArray.map((selection) => selection.key);
  };

  return { formLoader, submitQuestionnaireResponse, setFormDataFromPathwayData };
};
