// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearResultsBadge } from '../state/actions/NotificationsActions';
import { FormPie } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormPie';
//import { FormsStyles } from '../../waferJS/FormsUI/FormsUI';
//import { ENVIRONMENT } from '../../config/config';
import { FormButton } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { QuidelOrthoPathWay, QuidelOrthoPathWay2 } from '../../helpers/URLHelper';
import { withRouter } from '../routers/withRouter';

//COMPONENT
class Sidebar extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            collapsed: props.collapsed,
            marginOffset: 0,
            showToast: true,
            mainForm:  props.formLoader.mainForm(this.props.pathwayId),
            activeCellProgress: {},
            activeLinks: this.activeLinkSet(props.location),
        };

        this.constructSideBar = this.constructSideBar.bind(this);
        this.constructSection = this.constructSection.bind(this);
        this.hideSideBar = this.hideSideBar.bind(this);
        this.activeLinkSet = this.activeLinkSet.bind(this);
        this.pushToSummary = this.pushToSummary.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    numberOfUrlComponents(pathname) {
        let components = pathname.split('/');
        return components.length;
    }

    handleMenuItemClick(path) {
        this.props.navigate('/' + this.props.pathwayId + '/' + path);
        if(window.document.scrollingElement != null){
            window.document.scrollingElement.scrollTop = 0;
        }
        if(path == 'resultsForm'){
            this.props.clearResultsBadge(this.props.pathwayId);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.hideSideBar();

        // window.gtag('event', 'navigated-with-sidebar', {
        //     event_category: 'action',
        //     event_label: 'navigated-with-sidebar'
        // });
    }

    isPageActive(pagePath) {
        let pathComponents = this.props.location.pathname.split('/');
        let pagePathComponents = pagePath.split('/');
        return pathComponents[pathComponents.length - 1].includes(pagePathComponents[pagePathComponents.length - 1]);
    }

    activeLinkSet() {
        return this.props.formLoader.sidebarLayout(this.props.pathwayId).root;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.collapsed != this.props.collapsed) {
            this.setState({ collapsed: this.props.collapsed });
        }

        // Check if the URL has changed
        if (this.props.location !== prevProps.location) {
            // Perform actions when the URL changes
            let newActiveLinks = this.activeLinkSet(this.props.location);

            if (newActiveLinks.path !== this.state.activeLinks.path) {
                this.setState({ activeLinks: { pages: [], dataSections: [] } }, () => {
                    setTimeout(() => {
                        this.setState({ activeLinks: newActiveLinks });
                    }, 500);
                });
            }
      }
    }

    hideSideBar() {
        this.props.hideSideBar();
    }

    pushToSummary(){
        this.hideSideBar();
        this.props.navigate('/' + this.props.pathwayId + '/summary');
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount(){
        // this.unlisten();
        document.removeEventListener('click', this.handleClick);
    }

    handleClick(e) {
        if(this.props.formLoader.showSummary(this.props.pathwayId)){
            if (this.node != null && this.node.contains(e.target)) {
                this.pushToSummary();
            } 
        }
    }

    constructSideBar() {
        return (
            <div className="sidebar-content" key={'sidebar'}>
                <div className={'sidebar-links ' + (this.props.collapseHeader ? 'docked' : '')}>
                    {this.state.activeLinks.sections.map((section, index) => (
                        this.constructSection(section, index)
                    ))}
                </div>
                {this.props.formLoader.showSummary(this.props.pathwayId) &&
                    <div className="export-summary-container" ref={node => this.node = node}>
                        <FormButton textAlignment={'left'} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} title={'View Summary / Finish'} />
                    </div>
                }
            </div>
        );
    }

    constructSection(section, index) {
        let childSections = [];
        let clickEventAction = (event) => this.handleMenuItemClick(section.path, event);
        if (section.sections != undefined) {
            section.sections.map((section, index) => (
                childSections.push(this.constructSection(section, index))
            ));
        }

        let sectionIcon;
        if (section.icon != undefined) {
            sectionIcon = <div className='sidebar-item-icon-container'>
                <img className="sidebar-item-icon" src={require('../../images/' + section.icon + (this.isPageActive(section.path) ? ((this.props.pathwayId === QuidelOrthoPathWay || this.props.pathwayId === QuidelOrthoPathWay2) ? '-active-purple' : '-active') : '') + '.png')} />
            </div>;
        }

        let notificationBadge;
        if(section.path == 'resultsForm' && (this.props.showResultsBadge[this.props.pathwayId] != null && this.props.showResultsBadge[this.props.pathwayId] == true)){
            notificationBadge = <div className="notification-badge"/>;
        }

        let formPie;
        let formBadge;
        if (section.showPie) {
            let subformProgress = this.state.mainForm.subform(section.path).completionProgress();
            // console.log('Sidebar progress', section.path, subformProgress);
            // console.log('Test version', this.state.mainForm.subform(section.path).testVersion);
            if(subformProgress.current > 0){
                formPie = <div className="form-pie-container"><FormPie totalValue={subformProgress.total} filledValue={subformProgress.current} /></div>;
            }
        }

        // if(ENVIRONMENT == 'DEV' && section.showBadge){
        //     let badgeDetails;
        //     if(this.state.mainForm.subform(section.path) != null){
        //         badgeDetails = this.state.mainForm.getBadgeValue(section.path);
        //     } else {
        //         badgeDetails = FormLoader.form(this.props.pathwayId, section.path).getBadgeValue(section.path);
        //     }
            
        //     if(badgeDetails != null && badgeDetails.length > 0 && badgeDetails[0].solid){
                
        //         let badgeStyles = {
        //             backgroundColor: badgeDetails[0].solid ? badgeDetails[0].color : FormsStyles.backgroundColor
        //         };

        //         let innerStyles = {
        //             backgroundColor: badgeDetails[0].solid ? 'transparent' : badgeDetails[0].color 
        //         };

        //         formBadge = <div className={'side-bar-form-badge '} title={badgeDetails.map((badge) => {return badge.value; }).join(', ')} style={badgeStyles}>
        //             <div className="side-bar-form-badge-inner" style={innerStyles}></div>
        //         </div>; 
        //     }
        // }
        var shouldShow = true;
        let form = this.state.mainForm.subform(section.path);
        if(form !== null && form != undefined && typeof form.shouldShowInSidebar === 'function') {
            shouldShow = this.state.mainForm.subform(section.path).shouldShowInSidebar();
        }

        if(!shouldShow) {
            return null;
        }
        else {
            return (
                <div key={section.name} style={{ transitionDelay: (index * 50) + 'ms' }} className={'sidebar-item ' + (this.isPageActive(section.path) ? 'active ' : '') + 'selectable'} ref={node => this.node = node}>
                    <div>
                        <div className={'sidebar-item-header ' + (this.isPageActive(section.path) ? 'active ' : '')} onClick={clickEventAction} >
                            <div className="sidebar-item-header-content">
                                <div className="sidebar-item-content-left">
                                    <div className="sidebar-image-container">
                                        {sectionIcon}
                                        {notificationBadge}
                                    </div>
                                    {formPie}
                                    {this.isPageActive(section.path) &&
                                        <div className="acive-indicator"/>
                                    }
                                    <div className={'sidebar-item-name ' + (this.isPageActive(section.path) ? 'active' : '')} title={section.showPie ? this.state.mainForm.subform(section.path).detail : ''}  >{section.name}</div>
                                </div>
                                
                                {formBadge}
                            </div>
                        </div>
                        <div className='sidebar-item-children'>
                            {childSections}
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (this.constructSideBar());
    }
}

Sidebar.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    collapsed: PropTypes.bool.isRequired,
    hideSideBar: PropTypes.func.isRequired,
    showResultsBadge: PropTypes.object.isRequired,
    collapseHeader: PropTypes.bool.isRequired,
    workflows: PropTypes.object.isRequired,
    pathwayId: PropTypes.string.isRequired,
    clearResultsBadge: PropTypes.func.isRequired
};

// // CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { showResultsBadge } = state.notificationsReducer;
    const { workflows } = state.workflowReducer;
    return { showResultsBadge, workflows };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({clearResultsBadge}, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

// EXPORT COMPONENT
export { hoc as Sidebar };

