import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTCTBrainForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('ctBrainSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        if(identifier === "ctDateTime") {
            if(this.getValue('hasCTBeenPerformed') === true) {
                return true;
            }
            else {
                return false;
            }
        }
        if(identifier === 'isCTScanNormal') {
            if(this.getValue('hasCTBeenPerformed') === true) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'ctScanFindings') {
            return false;
        }
        else if (identifier == 'isCTScanNormal') {
            return this.getValue('hasCTBeenPerformed') == true;
        }
        else if (identifier === 'ctDateTime') {
            return this.getValue('hasCTBeenPerformed') === true;
        }
        
        return true;
    }

    getValueType(identifier) {
        if (identifier == 'isCTScanNormal' || identifier == 'hasCTBeenPerformed') {
            return 'boolean';
        }
        else if (identifier == 'ctScanFindings') {
            return 'string';
        }
        else if (identifier === 'ctDateTime') {
            return 'date';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }
}