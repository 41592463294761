export const calcAWPTA = (rawData, recollectionChecks, totalGCS) => {
  let totalAWPTA = 0;
  let validForRiskCategory = false;

  if (totalGCS == null || totalGCS == undefined) {
    return {totalAWPTA: totalAWPTA, 
        validForRiskCategory: validForRiskCategory};
  }

  for (let check of recollectionChecks) {
    let imageScoreData = rawData[check.check];

    if(imageScoreData !== null && imageScoreData !== undefined) {
        if(imageScoreData.length === 3) {
            totalAWPTA += 3;
            totalAWPTA += totalGCS;
            validForRiskCategory = true;
        }
        else {
            let imageScoreFollowUpData = rawData[check.recall];

            if (imageScoreFollowUpData != null && imageScoreFollowUpData != undefined) {
                let imageNames = imageScoreFollowUpData.map((image) => image.label);
            
                if (imageNames.includes('Cup')) {
                    totalAWPTA += 1;
                }
        
                if (imageNames.includes('Bird')) {
                    totalAWPTA += 1;
                }
        
                if (imageNames.includes('Keys')) {
                    totalAWPTA += 1;
                }
                validForRiskCategory = true;
            }
            totalAWPTA += totalGCS;
        }
    }
}

  return {totalAWPTA: totalAWPTA, 
        validForRiskCategory: validForRiskCategory};
}