let environment;

let functionsApiEndpoint;
let hcsEndpoint;
let accEndpoint;
let enabledExports; // hcs, ecpp

let pathwaysWithAuth;
let authTenant;
let authClient;

let enabledPathways;
let pilotPathways;
let cdhbPilotDisclaimerPathways;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'ds.wayfind.health') {
    environment = 'PROD';

    functionsApiEndpoint = 'https://ds.wayfind.health/api';
    enabledExports = ['acc'];

    enabledPathways = ['chestPain01', 'gallbladder01', 'dxaScan01', 'gpChestPain01', 'accmri01', 'accbist01'];
    pilotPathways = [];
    cdhbPilotDisclaimerPathways = ['gallbladder01', 'dxaScan01', 'gpChestPain01'];
    
    pathwaysWithAuth = ['chestPain01'];
    authTenant = '54189e82-0e09-4db8-ad3b-6d4dd601a1cd'; // Tenant ID
    authClient = '123a92e3-1b5a-4274-b480-2eb496c35363'; // Application ID / Client ID

    accEndpoint = 'https://x8yu4fxmt8.execute-api.ap-southeast-2.amazonaws.com/Prod/';

} else if (hostname == 'dev.wayfind.health' || hostname === 'localhost') {
    environment = 'DEV';
    
    functionsApiEndpoint = 'https://dev.wayfind.health/api';
    hcsEndpoint = 'https://app-rhapsody-d.cdhb.local:19080/';
    enabledExports = ['acc'];

    enabledPathways = ['chestPain01', 'gallbladder01', 'dxaScan01', 'gpChestPain01', 'accmri01', 'accbist01', 'QuidelOrtho', 'QuidelOrtho2', "pathway-selection"];
    pilotPathways = [];
    cdhbPilotDisclaimerPathways = ['gallbladder01', 'dxaScan01', 'gpChestPain01'];
    pathwaysWithAuth = [];

    if(hostname === 'localhost') {    
        accEndpoint = 'http://localhost:5000/';
    } else {
        accEndpoint = 'https://z9y0o7jjsi.execute-api.ap-southeast-2.amazonaws.com/Prod/';
    }

} else if (hostname == 'test.wayfind.health') {
    environment = 'TEST';
    
    functionsApiEndpoint = 'https://test.wayfind.health/api';
    hcsEndpoint = 'https://app-rhapsody-d.cdhb.local:19080/';
    enabledExports = ['ecpp', 'acc'];

    enabledPathways = ['chestPain01', 'gallbladder01', 'dxaScan01', 'gpChestPain01', 'accmri01', 'accbist01'];
    pilotPathways = [];
    cdhbPilotDisclaimerPathways = ['gallbladder01', 'dxaScan01', 'gpChestPain01'];

    pathwaysWithAuth = ['chestPain01'];
    authTenant = '54189e82-0e09-4db8-ad3b-6d4dd601a1cd'; // Tenant ID
    authClient = '62a684cd-f003-4df6-b56d-811cbc2eb85d'; // Application ID / Client ID

    accEndpoint = 'https://j5r7fjo2v2.execute-api.ap-southeast-2.amazonaws.com/Prod/';

} else if (hostname == 'staging.wayfind.health') {
    environment = 'STAGING';
    
    functionsApiEndpoint = null;
    enabledExports = ['acc'];
    
    enabledPathways = ['chestPain01', 'gallbladder01', 'dxaScan01', 'gpChestPain01', 'accmri01', 'accbist01'];
    pilotPathways = [];
    cdhbPilotDisclaimerPathways = ['gallbladder01', 'dxaScan01', 'gpChestPain01'];
    pathwaysWithAuth = [];

    accEndpoint = 'https://j5r7fjo2v2.execute-api.ap-southeast-2.amazonaws.com/Prod/';

} else if (hostname == 'demo.wayfind.health' || hostname == 'localhost') {
    environment = 'DEMO';

    functionsApiEndpoint = null;
    enabledExports = [];

    enabledPathways = ['chestPain01', 'gallbladder01', 'dxaScan01', 'gpChestPain01', 'QuidelOrtho', 'QuidelOrtho2'];
    pilotPathways = [];
    cdhbPilotDisclaimerPathways = ['gallbladder01', 'dxaScan01', 'gpChestPain01'];
    pathwaysWithAuth = [];

    accEndpoint = 'https://j5r7fjo2v2.execute-api.ap-southeast-2.amazonaws.com/Prod/';

}

export const ENVIRONMENT = environment;

export const FUNCTIONS_API_ROOT = `${functionsApiEndpoint}`;
export const HCS_API_ROOT = `${hcsEndpoint}`;
export const ACC_API_ROOT = `${accEndpoint}`;
export const ENABLED_EXPORTS = enabledExports;

export const PATHWAYS_WITH_AUTH = pathwaysWithAuth;
export const AUTH_TENANT = authTenant;
export const AUTH_CLIENT = authClient;

export const ENABLED_PATHWAYS = enabledPathways;
export const PILOT_PATHWAYS = pilotPathways;
export const CDHB_PILOT_DISCLAIMER_PATHWAYS = cdhbPilotDisclaimerPathways;