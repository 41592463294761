export class QuidelOrthoChestPainPathway2Reference {
    static data = {
        'title': 'ED Chest Pain: Suspected ACS',
        'detail': 'For patients where cardiac ischaemia is a genuine concern and inpatient investigation for another diagnosis is not required.\n\nThe pathway is designed for patients who might otherwise be discharged from hospital if acute myocardial infarction can be safely ruled out.',
        'itemType': 'template',
        'template.version': 1.0,
        'template.versionUuid': 'cardiacChestPain',
        'template.createText': 'Use This Pathway',
        'template.introID': 'introForm',
        'template.contentTabIDs': ['notesForm', 'mainForm', 'resultsForm'],
        'template.defaultContentTabID': 'mainForm',
        'template.content': {
            'introForm': {
                'title': 'ED Chest Pain: Suspected ACS',
                'detail': 'For patients where cardiac ischaemia is a genuine concern and inpatient investigation for another diagnosis is not required.\n\nThe pathway is designed for patients who might otherwise be discharged from hospital if acute myocardial infarction can be safely ruled out.',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'additionalInformation'
                ]
            },
            'additionalInformation': {
                'title': 'Additional Information',
                'itemType': 'section',
                'section.childIDs': [
                    'pathwayInformationForm'
                ]
            },
            'pathwayInformationForm': {
                'title': 'Additional Information',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'pathwayInformationSection'
                ]
            },
            'pathwayInformationSection': {
                'title': '',
                'itemType': 'section',
                'section.childIDs': [
                    'pathwayObjectivesInfo',
                    'requiredInvestigationsInfo',
                    'calculatedInsightsInfo'
                ]
            },
            'pathwayObjectivesInfo': {
                'title': 'Pathway Objectives',
                'detail': 'This pathway is designed to simultaneously\n- Support your clinical decisions\n- Create a clinical record\n- Collect high quality research data',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'requiredInvestigationsInfo': {
                'title': 'Required Investigations',
                'detail': 'Vital signs, ECG, Initial and Repeat troponin',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'calculatedInsightsInfo': {
                'title': 'Calculated Insights',
                'detail': 'EDACS and Risk Category',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'eligibilityForm': {
                'title': 'Eligibility criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'eligibilitySection'
                ]
            },
            'eligibilitySection': {
                'title': 'Eligibility section',
                'itemType': 'section',
                'section.childIDs': [
                    'eligibilityReason'
                ]
            },
            'eligibilityReason': {
                'title': 'Eligibility reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'notesForm': {
                'title': 'Notes',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'notesSection'
                ]
            },
            'mainForm': {
                'title': 'Pathway',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'patientDetailsSection',
                    'highRiskDifferentialDiagnosisMainSection',
                    'clinicalAssessment',
                    'investigations',
                ]
            },
            'patientDetailsSection': {
                'title': 'Patient details',
                'itemType': 'section',
                'section.childIDs': [
                    'patientDetails'
                ]
            },
            'patientDetails': {
                'title': 'Patient details',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'detailsSection'
                ]
            },
            'detailsSection': {
                'section.childIDs': [
                    'patientNHINumber',
                    'patientAge',
                    'patientSex'
                ]
            },
            'patientNHINumber': {
                'title': 'NHI number',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'singleLinePlainText'
            },
            'patientAge': {
                'title': 'Age',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'years',
                'subcellIDs':[
                    'patientDateOfBirth'
                ]
            },
            'patientDateOfBirth':{
                'title': 'Date of birth',
                'detail': 'Date of birth can be used to calculate age',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateOfBirth',
            },
            'patientSex': {
                'title': 'Sex',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'genderMaleOption',
                    'genderFemaleOption',
                    'genderOtherOption'
                ],
                'subcellIDs': [
                    'patientSexIssue'
                ]
            },
            'genderMaleOption': {
                'title': 'Male',
                'itemType': 'option'
            },
            'genderFemaleOption': {
                'title': 'Female',
                'itemType': 'option'
            },
            'genderOtherOption': {
                'title': 'Other',
                'itemType': 'option'
            },
            'patientSexIssue': {
                'title': 'Cardiac Risk is determined by several factors including biological sex. Please seek specialist advice to ensure this pathway is appropriate',
                'itemType': 'control',
                'control.type': 'plain'
            },
            'highRiskDifferentialDiagnosisMainSection': {
                'title': 'Clinical context',
                'itemType': 'section',
                'section.childIDs': [
                    'highRiskDifferentialDiagnosis'
                ]
            },
            'highRiskDifferentialDiagnosis': {
                'title': 'Clinical question',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'highRiskDifferentialDiagnosisSection'
                ]
            },
            'highRiskDifferentialDiagnosisSection': {
                'title': 'High-risk differential diagnoses',
                'detail': 'Are you actively considering any of the following:',
                'section.childIDs': [
                    'aorticDissectionPresent',
                    'oesophagealRupture',
                    'pulmonaryEmbolism',
                    'pancreatitis',
                    'pneumothorax',
                    'noToAllHighRiskDifferentialDiagnosis'
                ]
            },
            'noToAllHighRiskDifferentialDiagnosis': {
                'title': '',
                'itemType': 'control',
                'control.type': 'noToAll'
            },
            'highOption': {
                'title': 'High',
                'itemType': 'option'
            },
            'lowOption': {
                'title': 'Low',
                'itemType': 'option'
            },
            'noneOption': {
                'title': 'None',
                'itemType': 'option'
            },
            'notesSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'freeformNotes'
                ]
            },
            'freeformNotes': {
                'itemType': 'control',
                'control.type': 'textEntry',
                'mandatory': false,
                'textEntry.type': 'wrappingPlainTextLarge',
            },
            'clinicalAssessment': {
                'title': 'Clinical assessment',
                'itemType': 'section',
                'section.childIDs': [
                    'initialAssessment'
                ]
            },
            'initialAssessment': {
                'title': 'EDACS assessment',
                'detail': 'Timing of pain, Key clinical features & Key historical information',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'firstEpisodeSection',
                    'keyAssessmentInformation'
                ]
            },
            'firstEpisodeSection': {
                'title': 'Timing of chest pain',
                'itemType': 'section',
                'section.childIDs': [
                    'firstDateTime',
                    'firstDuration'
                ]
            },
            'firstDateTime': {
                'title': 'Start date and time',
                'detail': 'The episode of chest pain leading to presentation. For fluctuating pain use the worst episode',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime',
                'validity.function': 'firstDateValidity'
            },
            'firstDuration': {
                'title': 'Duration',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'mins'
            },
            'keyAssessmentInformation': {
                'title': 'Key assessment information',
                'itemType': 'section',
                'section.childIDs': [
                    'keyClinicalFeatures',
                    'keyHistoricalInformation'
                ]
            },
            'keyClinicalFeatures': {
                'title': 'Key clinical features',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'symptomsAndSigns'
                ]
            },
            'symptomsAndSigns': {
                'title': 'Symptoms and signs',
                'itemType': 'section',
                'section.childIDs': [
                    'diaphoresis',
                    'painRadiates',
                    'pleuriticPain',
                    'painByPalpation',
                    'crescendoPain',
                    'ongoingChestPain'
                ]
            },
            'diaphoresis': {
                'title': 'Diaphoresis/sweating',
                'detail': 'In association with this pain episode',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'painRadiates': {
                'title': 'Pain radiates to arm, shoulder, neck or jaw',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pleuriticPain': {
                'title': 'Pleuritic pain',
                'detail': 'Sudden sharp increase in pain while breathing in localised to a particular area of the chest wall',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'painByPalpation': {
                'title': 'Pain reproduced by palpation',
                'detail': 'Pain leading to this presentation',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'crescendoPain': {
                'title': 'Crescendo pain',
                'detail': 'Such as multiple episodes in 24h or any escalating frequency of pain',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'ongoingChestPain': {
                'title': 'Ongoing chest pain',
                'detail': 'Pain continues at rest or minimal exertion',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'keyHistoricalInformation': {
                'title': 'Key historical information',
                'detail': 'Problems, past medical history, drug history, family history and social history',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'problemsAndPast',
                    'familyHistory',
                    'medications',
                ]
            },
            'problemsAndPast': {
                'title': 'Problems and past medical history',
                'itemType': 'section',
                'section.childIDs': [
                    'hypertension',
                    'dyslipidaemia',
                    'diabetes',
                    'currentSmoker',
                    'obesity',
                    'coronaryHeartDisease',
                    'coronaryArteryStenosis'
                ]
            },
            'hypertension': {
                'title': 'Hypertension',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'dyslipidaemia': {
                'title': 'Dyslipidaemia',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'diabetes': {
                'title': 'Diabetes',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'currentSmoker': {
                'title': 'Current smoker',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'obesity': {
                'title': 'Obesity',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'coronaryHeartDisease': {
                'title': 'Coronary heart disease',
                'detail': 'Previous myocardial infarction, CABG or PCI',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'coronaryArteryStenosis': {
                'title': 'Coronary artery stenosis',
                'detail': 'Documented >50% stenosis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'familyHistory': {
                'title': 'Family history',
                'itemType': 'section',
                'section.childIDs': [
                    'prematureCoronaryHeartDisease'
                ]
            },
            'prematureCoronaryHeartDisease': {
                'title': 'FH: Premature coronary heart disease',
                'detail': 'First degree relatives with disease in men <55yrs or women <65yrs',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'medications': {
                'title': 'Medications',
                'itemType': 'section',
                'section.childIDs': [
                    'aspirin',
                ]
            },
            'aspirin': {
                'title': 'Aspirin',
                'detail': 'Regular use within last 7 days',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'investigations': {
                'title': 'Investigations',
                'itemType': 'section',
                'section.childIDs': [
                    'vitalSigns',
                    'ecgTest',
                    'initialBloods'
                ]
            },
            'vitalSigns': {
                'title': 'Vital signs',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'vitalSignsDetails',
                    'recordVitalsSection'
                ]
            },
            'vitalSignsDetails': {
                'itemType': 'section',
                'section.childIDs': [
                    'areSignsNormal'
                ]
            },
            'areSignsNormal':{
                'title': 'Are the vital signs normal for this patient?',
                'detail': 'Consider pre-existing conditions and chronic disease',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'patientCannotBeNormalTotal',
                    'patientCannotBeNormalSingleField'
                ]
            },
            'patientCannotBeNormalTotal':{
                'title': 'A TOTAL EWS score ≥6 cannot be classified as normal',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'patientCannotBeNormalSingleField':{
                'title': 'Any individual EWS score ≥3 cannot be classified as normal',
                'itemType': 'control',
                'control.type': 'plain',
            },

            'recordVitalsSection': {
                'title': 'Record vital signs',
                'detail': 'Enter a complete set of vital signs to calculate the EWS score',
                'itemType': 'section',
                'section.childIDs': [
                    'heartRate',
                    'bloodPressure',
                    'bodyTemperature',
                    'oxygenSaturation',
                    'respiratoryRate',
                    'supplementalOxygen',
                    'levelOfConsciousness'
                ]
            },
            'heartRate': {
                'title': 'Heart rate',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'bpm',
                'subcellIDs': [
                    'heartRateEWS'
                ]
            },
            'heartRateEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'bloodPressure': {
                'title': 'Blood pressure',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'bloodPressure',
                'textEntry.unitText': 'mmHg',
                'subcellIDs': [
                    'bloodPressureEWS'
                ]
            },
            'bloodPressureEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'bodyTemperature': {
                'title': 'Body temperature',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '℃',
                'subcellIDs': [
                    'bodyTemperatureEWS'
                ]
            },
            'bodyTemperatureEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'oxygenSaturation': {
                'title': 'Oxygen saturation (SpO₂)',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': '%',
                'subcellIDs': [
                    'oxygenSaturationEWS'
                ]
            },
            'oxygenSaturationEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'respiratoryRate': {
                'title': 'Respiratory rate',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'decimalNumber',
                'textEntry.unitText': 'bpm',
                'subcellIDs': [
                    'respiratoryRateEWS'
                ]
            },
            'respiratoryRateEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'supplementalOxygen': {
                'title': 'Supplemental oxygen',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'supplementalOxygenEWS'
                ]
            },
            'supplementalOxygenEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'levelOfConsciousness': {
                'title': 'Level of consciousness',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'simple',
                'selection.options': [
                    'responsivenessAlert',
                    'responsivenessVoiceOrPain',
                    'responsivenessUnresponsive'
                ],
                'subcellIDs': [
                    'levelOfConsciousnessEWS'
                ]
            },
            'levelOfConsciousnessEWS': {
                'title': 'Early Warning Score',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'responsivenessAlert': {
                'title': 'Alert',
                'itemType': 'option'
            },
            'responsivenessVoiceOrPain': {
                'title': 'Voice or pain',
                'itemType': 'option'
            },
            'responsivenessUnresponsive': {
                'title': 'Unresponsive',
                'itemType': 'option'
            },
            'ecgTest': {
                'title': 'ECG',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'stemiSection',
                    'abnormalitiesSection'
                ]
            },
            'stemiSection': {
                'title': 'STEMI',
                'itemType': 'section',
                'section.childIDs': [
                    'stemiPresent'
                ]
            },
            'stemiPresent': {
                'title': 'Is this ST elevation myocardial infarction (STEMI)?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'stemiCriteria'
                ]
            },
            'stemiCriteria': {
                'title': 'STEMI criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'stemiCriteriaSection'
                ],
                'visibility.function': 'stemiCriteriaVisibility',
                'mandatory.function': 'stemiCriteriaMandatory'
            },
            'stemiCriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'stemiCriteriaV4toV6',
                    'stemiCriteriaV1toV3',
                    'stemiCriteriaV7toV9',
                    'stemiCriteriaNewLBBB',
                    'stemiCriteriaLBBBWithPositiveQRS',
                    'stemiCriteriaLBBBWithNegativeQRS',
                    'noToAllSTEMI'
                ]
            },
            'stemiCriteriaV4toV6': {
                'title': 'Contiguous ST elevation >1mm in leads V4 to V6 or limb leads',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaV1toV3': {
                'title': 'Contiguous ST elevation >2mm in leads V1 to V3',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaV7toV9': {
                'title': 'Contiguous ST elevation >0.5mm in posterior leads V7 to V9',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaNewLBBB': {
                'title': 'Left bundle branch block (LBBB) that is known to be new, with typical ischaemic symptoms',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaLBBBWithPositiveQRS': {
                'title': 'LBBB or paced rhythm with ST elevation >1 mm in leads with a QRS complex that is mainly positive',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'stemiCriteriaLBBBWithNegativeQRS': {
                'title': 'LBBB or paced rhythm with ST elevation >5 mm in leads with a QRS complex that is mainly negative',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'noToAllSTEMI': {
                'title': '',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            'abnormalitiesSection': {
                'title': 'Other abnormalities',
                'itemType': 'section',
                'section.childIDs': [
                    'abnormalitiesPresent'
                ]
            },
            'abnormalitiesPresent': {
                'title': 'Are there any other abnormalities present?',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'abnormalitiesCriteria'
                ]
            },
            'abnormalitiesCriteria': {
                'title': 'Other abnormalities',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'abnormalitiesCriteriaSection'
                ],
                'visibility.function': 'abnormalitiesCriteriaVisibility',
                'mandatory.function': 'abnormalitiesCriteriaMandatory'
            },
            'abnormalitiesCriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'newIschaemia',
                    'oldIschaemia',
                    'oldLBBB',
                    'nonSpecificSTorTChanges',
                    'alternativeFinding',
                    'noToAllAbnormalities'
                ]
            },
            'newIschaemia': {
                'title': 'New ischaemia',
                'detail': 'ST depression >0.5mm in contiguous leads, T wave inversion >1mm, or Q-waves >30ms. Highest risk is widespread ST depression, deep T wave inversion or dynamic changes over time',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'oldIschaemia': {
                'title': 'Old ischaemia',
                'detail': 'As above',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'oldLBBB': {
                'title': 'Old Left bundle branch block (LBBB)',
                'detail': 'LBBB that does not fulfil STEMI criteria',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'nonSpecificSTorTChanges': {
                'title': 'Non-specific ST or T wave changes',
                'detail': 'Deviation from normal with very low likelihood of ischaemia (eg. Inverted T wave in III or V1)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'alternativeFinding': {
                'title': 'An alternative finding',
                'detail': 'Abnormalities not diagnostic of ischaemia (eg. conduction abnormality, AF, LVH)',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'alternativeFindingSpecify'
                ]
            },
            'alternativeFindingSpecify': {
                'title': 'Specify',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText',
                'visibility.function': 'alternativeFindingSpecifyVisibility'
            },
            'noToAllAbnormalities': {
                'title': '',
                'itemType': 'control',
                'control.type': 'noToAll'
            },

            'initialBloods': {
                'title': 'Bloods',
                // 'detail': 'Troponin',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'initialBloodsDetailsSection',
                    'followUpBloodsDetailsSection'
                ]
            },
            'initialBloodsDetailsSection': {
                'title': 'Troponin I - Initial',
                'itemType': 'section',
                'section.childIDs': [
                    'initialBloodsDateAndTime',
                    'initialBloodsTroponin'
                ]
            },
            'initialBloodsDateAndTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'initialBloodsTroponin': {
                'title': 'Troponin result',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'qoTroponin2',
                'textEntry.unitText': 'ng/L'
            },
            'followUpBloodsDetailsSection': {
                'title': 'Troponin I - Repeat',
                'itemType': 'section',
                'section.childIDs': [
                    'followUpBloodsDateAndTime',
                    'followUpBloodsTroponin',
                ]
            },
            'followUpBloodsDateAndTime': {
                'title': 'Date and time',
                'itemType': 'control',
                'control.type': 'picker',
                'picker.type': 'dateTime'
            },
            'followUpBloodsTroponin': {
                'title': 'Troponin result',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'qoTroponin2',
                'textEntry.unitText': 'ng/L'
            },
            'redFlagsSection': {
                'title': 'Red flags',
                'detail': 'Findings requiring urgent assessment and management',
                'itemType': 'section',
                'section.childIDs': [
                    'highRiskDifferentialDiagnosisRedFlagsForm',
                    'highRiskFindingsRedFlagsForm'
                ]
            },
                
            'highRiskDifferentialDiagnosisRedFlagsForm': {
                'title': 'High-risk differential diagnoses',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'highRiskDifferentialDiagnosisRedFlags'
                ]
            },
            'highRiskDifferentialDiagnosisRedFlags': {
                'itemType': 'section',
                'section.childIDs': [
                    'aorticDissectionPresentRedFlag',
                    'oesophagealRuptureRedFlag',
                    'pulmonaryEmbolismRedFlag',
                    'pancreatitisRedFlag',
                    'pneumothoraxRedFlag',
                ]
            },
            
            'aorticDissectionPresent': {
                'title': 'Aortic dissection',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit',
                'subcellIDs': [
                    'aorticDissectionCriteria'
                ]
            },
            'aorticDissectionPresentRedFlag': {
                'title': 'Aortic dissection',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'aorticDissectionPresentRedFlagOverride',
                    'aorticDissectionPresentRedFlagOverrideReason'
                ]
            },
            'aorticDissectionPresentRedFlagOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'aorticDissectionPresentRedFlagOverrideValue': {
                'title': 'New value',
                'itemType': 'control',
                'control.type': 'badge'
            },
            'aorticDissectionPresentRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'aorticDissectionCriteria': {
                'title': 'Aortic dissection criteria',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'aorticDissectionCriteriaSection'
                ],
                'visibility.function': 'aorticDissectionCriteriaVisibility'
            },
            'aorticDissectionCriteriaSection': {
                'itemType': 'section',
                'section.childIDs': [
                    'aorticDissectionCriteriaHighRiskConditions',
                    'aorticDissectionCriteriaHighRiskSymptoms',
                    'aorticDissectionCriteriaHighRiskFindings'
                ]
            },
            'aorticDissectionCriteriaHighRiskConditions': {
                'title': 'Are high-risk conditions present?',
                'detail': 'Marfan syndrome, aortic valve disease, aortic aneurysm, family history of aortic disease or recent aortic manipulation',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'aorticDissectionCriteriaHighRiskSymptoms': {
                'title': 'Are high-risk symptoms present?',
                'detail': 'Chest, back or abdominal pain that is abrupt, severe intensity, ripping or tearing',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'aorticDissectionCriteriaHighRiskFindings': {
                'title': 'Are high-risk findings present?',
                'detail': 'Pulse or BP differential, focal neurological changes, murmur of aortic insufficiency, Hypotension or shock',
                'itemType': 'control',
                'control.type': 'plain',
            },
            'pulmonaryEmbolism': {
                'title': 'Pulmonary embolism',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pulmonaryEmbolismRedFlag': {
                'title': 'Pulmonary embolism',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pulmonaryEmbolismRedFlagOverride',
                    'pulmonaryEmbolismRedFlagOverrideReason'
                ]
            },
            'pulmonaryEmbolismRedFlagOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pulmonaryEmbolismRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'pancreatitis': {
                'title': 'Pancreatitis',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pancreatitisRedFlag': {
                'title': 'Pancreatitis',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pancreatitisRedFlagOverride',
                    'pancreatitisRedFlagOverrideReason'
                ]
            },
            'pancreatitisRedFlagOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pancreatitisRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'oesophagealRupture': {
                'title': 'Oesophageal rupture',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'oesophagealRuptureRedFlag': {
                'title': 'Oesophageal rupture',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'oesophagealRuptureRedFlagOverride',
                    'oesophagealRuptureRedFlagOverrideReason'
                ]
            },
            'oesophagealRuptureRedFlagOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'oesophagealRuptureRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'pneumothorax': {
                'title': 'Pneumothorax',
                'itemType': 'control',
                'control.type': 'selection',
                'selection.style': 'explicit'
            },
            'pneumothoraxRedFlag': {
                'title': 'Pneumothorax',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'pneumothoraxRedFlagOverride',
                    'pneumothoraxRedFlagOverrideReason'
                ]
            },
            'pneumothoraxRedFlagOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'pneumothoraxRedFlagOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'highRiskFindingsRedFlagsForm': {
                'title': 'High-risk findings',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'highRiskFindingsRedFlags'
                ]
            },
            'highRiskFindingsRedFlags': {
                'itemType': 'section',
                'section.childIDs': [
                    'redFlagsOngoingChestPain',
                    'crescendoAngina',
                    'haemodynamicInstability',
                    'newIschaemicChanges'
                ]
            },
            'newIschaemicChanges': {
                'title': 'New ischaemic changes',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'newIschaemicChangesOverride',
                    'newIschaemicChangesOverrideReason'
                ]
            },
            'newIschaemicChangesOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'newIschaemicChangesOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'redFlagsOngoingChestPain': {
                'title': 'Ongoing chest pain',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'redFlagsOngoingChestPainOverride',
                    'redFlagsOngoingChestPainOverrideReason'
                ]
            },
            'redFlagsOngoingChestPainOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'redFlagsOngoingChestPainOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'crescendoAngina': {
                'title': 'Crescendo angina',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'crescendoAnginaOverride',
                    'crescendoAnginaOverrideReason'
                ]
            },
            'crescendoAnginaOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'crescendoAnginaOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'haemodynamicInstability': {
                'title': 'Haemodynamic instability',
                'detail': 'EWS ≥2 may signify instability. Check patient status',
                'itemType': 'control',
                'control.type': 'redflag',
                'subcellIDs': [
                    'haemodynamicInstabilityOverride',
                    'haemodynamicInstabilityOverrideReason'
                ]
            },
            'haemodynamicInstabilityOverride': {
                'title': 'Override the red flag',
                'detail': 'This will update Wayfind recommendations',
                'itemType': 'control',
                'control.type': 'radio',
            },
            'haemodynamicInstabilityOverrideReason': {
                'title': 'Override reason',
                'itemType': 'control',
                'control.type': 'textEntry',
                'textEntry.type': 'wrappingPlainText'
            },
            'resultsForm': {
                'title': 'Insights',
                'itemType': 'subform',
                'subform.sectionIDs': [
                    'redFlagsSection',
                    'insights',
                    'actions',
                    'assessmentTeam',
                    'referralDisposition',
                    'resultsInvestigations'
                ]
            },
            'assessmentTeam': {
                'title': 'Assessment Team',
                'itemType': 'section',
                'section.childIDs': [
                    'emergencyMedicineAssessment', //1
                    'cardiologyAssessment', //2
                ]
            },
            'referralDisposition': {
                'title': 'Referral & Disposition',
                'itemType': 'section',
                'section.childIDs': [
                    'admitToChestPainUnitOrCardiologyWard', //1
                    'discussWithCardiology', //2
                    'dischargeToAcuteDemandService', //3
                    'cardiologySMOReview', //4
                    'dischargeToGP', //5
                ]
            },
            'resultsInvestigations': {
                'title': 'Investigations',
                'itemType': 'section',
                'section.childIDs': [
                    'repeatTroponinAction', //1
                    'repeatECGAction', //2
                    'followCardiologyAdviceAction', //3
                    'noFurtherInvestigationRequiredAction', //4
                ]
            },
            'actions': {
                'title': 'Alerts',
                'itemType': 'section',
                'section.childIDs': [
                    'stemiAction', //1
                    'invalidPatientAction', //2
                    'reviewWithSMO', //3
                    'clinicalEmergency', //4
                    'clinicalInstability', //4
                    'clinicalEmergencyDeterioration' //4
                ]
            },
            'emergencyMedicineAssessment': {
                'title': 'Emergency Medicine',
                'detail': 'Emergency Medicine team is recommended, unless another team is more appropriate',
                'itemType': 'control',
                'control.type': 'action'
            },
            'cardiologyAssessment': {
                'title': 'Cardiology',
                'detail': 'Cardiology team is recommended, unless another team is more appropriate',
                'itemType': 'control',
                'control.type': 'action'
            },

            'repeatTroponinAction': {
                'title': 'Bloods - Repeat',
                'itemType': 'control',
                'control.type': 'action'
            },
            'repeatECGAction': {
                'title': 'ECG',
                'detail': 'Repeat ECG if the patient status changes',
                'itemType': 'control',
                'control.type': 'action'
            },
            'followCardiologyAdviceAction': {
                'title': 'Follow Cardiology advice',
                'detail': 'Consider risk factors and risk factor modification. Follow-up tests may include Exercise tolerance test (ETT), CT Coronary Angiogram (CTCA) or Invasive coronary angiogram.\nLinks: #@^ETT request^https://prism.cdhb.health.nz/site/policies/Published/Outpatient-ETT-Request-Form-for-possible-angina-chest-pain.docx#@, #@^ETT patient information^https://prism.cdhb.health.nz/site/policies/Published/Exercise%20Tolerance%20Test.docx#@, #@^CTCA request^https://cdhbintranet.cdhb.health.nz/medicalandsurgical/radiologyservices/Documents/Referral-Form-Cardiac-CT-Referral-55073.pdf#@, #@^CTCA patient information^https://cdhbintranet.cdhb.health.nz/medicalandsurgical/radiologyservices/Patient%20Preparation/Patient-Information-Coronary-CT-(2468-v22).pdf#@',
                'itemType': 'control',
                'control.type': 'action'
            },
            'noFurtherInvestigationRequiredAction': {
                'title': 'No further investigation required',
                'detail': 'Unless concerning features are present',
                'itemType': 'control',
                'control.type': 'action'
            },

            'stemiAction': {
                'title': 'Activate STEMI Team',
                'detail': 'STEMI requires time critical management. Active cardiology cath lab',
                'itemType': 'control',
                'control.type': 'action'
            },
            'clinicalEmergencyDeterioration': {
                'title': 'Clinical Emergency',
                'detail': 'EWS 8-9 is a clinical emergency. Likely to deteriorate rapidly',
                'itemType': 'control',
                'control.type': 'action',
            },
            'clinicalInstability': {
                'title': 'Clinical Instability',
                'detail': 'EWS 6-7 may indicate acute illness or unstable chronic disease',
                'itemType': 'control',
                'control.type': 'action'
            },

            'admitToChestPainUnitOrCardiologyWard': {
                'title': 'Admit to Chest Pain Unit or Cardiology Ward',
                'detail': 'Request cardiology review unless medical review for non-invasive management is more appropriate',
                'itemType': 'control',
                'control.type': 'action',
            },
            'discussWithCardiology': {
                'title': 'Discuss with Cardiology',
                'detail': 'Seek urgent cardiology advice by contacting the on-call cardiologist. Continue to manage long term cardiovascular risk factors',
                'itemType': 'control',
                'control.type': 'action',
            },
            'dischargeToAcuteDemandService': {
                'title': 'Discharge to Acute Demand Service',
                'detail': 'Refer for next day investigation.\nLinks: #@^Patient information^https://prism.cdhb.health.nz/Site/ED/_layouts/15/DocIdRedir.aspx?ID=CDHB-5534356-1012#2#@, #@^Laboratory request^https://prism.cdhb.health.nz/Site/ED/_layouts/15/DocIdRedir.aspx?ID=CDHB-5534356-1011#@, #@^Acute Demand information^https://prism.cdhb.health.nz/Site/ED/_layouts/15/DocIdRedir.aspx?ID=CDHB-5534356-1019#@',
                'itemType': 'control',
                'control.type': 'action',
            },
            'cardiologySMOReview': {
                'title': 'Cardiology SMO review',
                'detail': 'Cardiology SMO will review case notes and determine further management. Review will occur either in SMO virtual clinic or with GP within 1 week. Continue to manage long term cardiovascular risk factors',
                'itemType': 'control',
                'control.type': 'action',
            },
            'dischargeToGP': {
                'title': 'Discharge to GP',
                'detail': 'Review within 1 week for cardiovascular risk assessment and review of any ongoing symptoms. Continue to manage long term cardiovascular risk factors.\nLink: #@^Patient information^https://prism.cdhb.health.nz/Site/ED/_layouts/15/DocIdRedir.aspx?ID=CDHB-5534356-1012#@',
                'itemType': 'control',
                'control.type': 'action',
            },
            
            'reviewWithSMO': {
                'title': 'Review with SMO',
                'detail': 'Senior assessment and management is needed. Ensure Red flags are resolved before continuing this pathway',
                'itemType': 'control',
                'control.type': 'action'
            },
            'clinicalEmergency': {
                'title': 'Clinical Emergency',
                'detail': 'EWS 10+ is a clinical emergency. Immediately life threatening critical illness',
                'itemType': 'control',
                'control.type': 'action'
            },
            'insights': {
                'title': 'Calculated Insights',
                'itemType': 'section',
                'section.childIDs': [
                    'calculatedEDACS',
                    'pathwayRiskCategory'
                ]
            },
            'calculatedEDACS': {
                'title': 'Calculated EDACS',
                'itemType': 'control',
                'control.type': 'calculation',
            },
            'pathwayRiskCategory': {
                'title': 'Pathway Risk Category',
                'itemType': 'control',
                'control.type': 'calculation',

            },
            'invalidPatientAction': {
                'title': 'Patient is not eligible for pathway',
                'detail': 'Due to indicated patient sex this patient is not eligible for continuing this pathway. No further recommendations will be provided',
                'itemType': 'control',
                'control.type': 'action'
            },
        },
        'template.functions': {
            'otherEpisodeVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'painWorstAt'
                ],
                'updateInterfaceOnChange': true
            },
            'stemiCriteriaVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'stemiPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'stemiCriteriaMandatory': {
                'functionType': 'mandatory',
                'dependencies': [
                    'stemiPresent'
                ],
                'updateInterfaceOnChange': true
            },

            'abnormalitiesCriteriaVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'abnormalitiesPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'abnormalitiesCriteriaMandatory': {
                'functionType': 'mandatory',
                'dependencies': [
                    'abnormalitiesPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'alternativeFindingSpecifyVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'alternativeFinding'
                ],
                'updateInterfaceOnChange': true
            },
            'aorticDissectionCriteriaVisibility': {
                'functionType': 'visibility',
                'dependencies': [
                    'aorticDissectionPresent'
                ],
                'updateInterfaceOnChange': true
            },
            'afterFirstDateValidity': {
                'functionType': 'validity',
                'dependencies': [
                    'firstDateTime',
                    'worstDateTime'
                ]
            },
            'firstDateValidity': {
                'functionType': 'validity',
                'dependencies': [
                    'firstDateTime'
                ]
            }
        }
    }
}