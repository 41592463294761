import { FormWithRedux } from '../../FormWithRedux';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormsStyles, FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import React from 'react';

export class ACCBISTGCSForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater, index) {
        super('gcsSectionSubform' + index, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
        this.index = index;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'totalGCSScore' + this.index) {
            let value = this.getValue('totalGCS' + this.index);

            if (value == null || value == undefined) {
                return false;
            }
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        let allFineCheck = this.getValue('neuroObsOkayCheck' + this.index);
        
        if (allFineCheck != null && allFineCheck != undefined && allFineCheck == true) {
            return false;
        }

        return true;
    }

    getValueType(identifier) {
        if (identifier == 'totalGCSScore' + this.index) {
            return 'double';
        }
        return 'string';
    }

    submissionValidation() {
        return [];
    }

    getBadgeValue(identifier) {
        if (identifier == 'totalGCSScore' + this.index) {
            let value = this.getValue('totalGCS' + this.index);

            if (value != null && value != undefined) {
                return [{
                    color: FormsStyles.black,
                    title: 'Total GCS',
                    value: value.toString(),
                    solid: false
                }];
            }
            else {
                return [];
            }
        }

        return [];
    }

    editingValidation(identifier) {
        // if (identifier == 'verbalScore') {
        //     let self = this;
    
        //     let valueConflict = new FormValidation('VerbalScoreConflict', function () {
        //         let orienationChecks1 = [
        //             self.getValue('whatIsYourNameCheck1'),
        //             self.getValue('nameOfThisPlaceCheck1'),
        //             self.getValue('whyAreYouHereCheck1'),
        //             self.getValue('whatMonthAreWeInCheck1'),
        //             self.getValue('whatYearAreWeInCheck1')];
    
        //         let orienationChecks2 = [
        //             self.getValue('whatIsYourNameCheck2'),
        //             self.getValue('nameOfThisPlaceCheck2'),
        //             self.getValue('whyAreYouHereCheck2'),
        //             self.getValue('whatMonthAreWeInCheck2'),
        //             self.getValue('whatYearAreWeInCheck2')];

        //         let orienationChecks3 = [
        //             self.getValue('whatIsYourNameCheck3'),
        //             self.getValue('nameOfThisPlaceCheck3'),
        //             self.getValue('whyAreYouHereCheck3'),
        //             self.getValue('whatMonthAreWeInCheck3'),
        //             self.getValue('whatYearAreWeInCheck3')];

        //         let orienationChecks4 = [
        //             self.getValue('whatIsYourNameCheck4'),
        //             self.getValue('nameOfThisPlaceCheck4'),
        //             self.getValue('whyAreYouHereCheck4'),
        //             self.getValue('whatMonthAreWeInCheck4'),
        //             self.getValue('whatYearAreWeInCheck4')];

        //         let orienationChecks5 = [
        //             self.getValue('whatIsYourNameCheck5'),
        //             self.getValue('nameOfThisPlaceCheck5'),
        //             self.getValue('whyAreYouHereCheck5'),
        //             self.getValue('whatMonthAreWeInCheck5'),
        //             self.getValue('whatYearAreWeInCheck5')];

        //         var incompatiblerResult = false;
        //         var showAsWarning = true;

        //         if (self.getValue('verbalScore') == 'Oriented') {
        //             showAsWarning = false;
        //         }

        //         if(!orienationChecks5.includes(null) && !orienationChecks5.includes(undefined)) {
        //             if(orienationChecks5.includes(false)) {
        //                 incompatiblerResult = true;
        //             }
        //         }
        //         else if(!orienationChecks4.includes(null) && !orienationChecks4.includes(undefined)) {
        //             if(orienationChecks4.includes(false)) {
        //                 incompatiblerResult = true;
        //             }
        //         }
        //         else if(!orienationChecks3.includes(null) && !orienationChecks3.includes(undefined)) {
        //             if(orienationChecks3.includes(false)) {
        //                 incompatiblerResult = true;
        //             }
        //         }
        //         else if(!orienationChecks2.includes(null) && !orienationChecks2.includes(undefined)) {
        //             if(orienationChecks2.includes(false)) {
        //                 incompatiblerResult = true;
        //             }
        //         }
        //         else if(!orienationChecks1.includes(null) && !orienationChecks1.includes(undefined)) {
        //             if(orienationChecks1.includes(false)) {
        //                 incompatiblerResult = true;
        //             }
        //         }

        //         if (incompatiblerResult) {
        //             return [new FormValidationError(showAsWarning ? FormValidationError.FormErrorType.warning : FormValidationError.FormErrorType.error, 'AWPTA suggests patient is confused')];
        //         }
        //         else {
        //             return [];
        //         }
        //     });

        //     return [valueConflict];

            
        // }

        return [];
    }
}