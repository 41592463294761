// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper'; 
import { QuidelOrthoChestPainPathway2Reference } from '../QuidelOrthoChestPainPathway2-Reference';
import { FormsContentType, FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { EDChestPainCalculationHelper } from '../Helpers/EDChestPainCalculationHelper';
import { PrintHeader } from '../../../components/shared/PrintHeader';
import { calculateEWS, EWSCategory, TotalEWS } from '../Custom Types/EWS';
import { ExportToHCSOverlay } from '../../../components/pages/Overlays/ExportToHCSOverlay';
import { Base64 } from '../../../helpers/Base64Encoder';
import ReactDOMServer from 'react-dom/server';

import moment from 'moment';

import '../../../styles/components/formSummary.scss';
import { CalculationModel } from  '../Custom Types/Calculation';
import { ENABLED_EXPORTS } from '../../../config/config';
import { QuidelOrtho2TroponinResult } from '../Custom Types/QuidelOrtho2Troponin';
import { EDChestPainExportToECPP } from '../Export/EDChestPain-ExportToECPP';

// COMPONENT
class QuidelOrthoChestPain2Summary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            complete: 0,
            total: 0,
            actions: [],
            exportHCSVisible: false,
            data: QuidelOrthoChestPainPathway2Reference.data,
            mainForm: this.props.formLoader.form(this.props.pathwayId , 'mainForm')
        };

        this.formatDate = this.formatDate.bind(this);
        this.generateNotes = this.generateNotes.bind(this);
        this.getItemsInSection = this.getItemsInSection.bind(this);
        this.getReadableValue = this.getReadableValue.bind(this);
        this.generateStemi = this.generateStemi.bind(this);
        this.generateAbnormalities = this.generateAbnormalities.bind(this);
        this.getMissingDetail = this.getMissingDetail.bind(this);
        this.formatList = this.formatList.bind(this);
        this.getDisparateItemsAsList = this.getDisparateItemsAsList.bind(this);
        this.getCalculationBadge = this.getCalculationBadge.bind(this);
        this.getRedFlagsBadge = this.getRedFlagsBadge.bind(this);
        this.getSummary = this.getSummary.bind(this);
        this.getRedFlagOverrideReasons = this.getRedFlagOverrideReasons.bind(this);
        this.areRedFlagPresents = this.areRedFlagPresents.bind(this);
        this.getMandatoryDetail = this.getMandatoryDetail.bind(this);
        this.getEWSBadge = this.getEWSBadge.bind(this);
        this.getTotalEWSBadge = this.getTotalEWSBadge.bind(this);
        this.getBadgeValue = this.getBadgeValue.bind(this);
        this.stripSpecialChars = this.stripSpecialChars.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newResultsAvailable == true && ENABLED_EXPORTS.includes('ecpp')) {
            let htmlBody = document.getElementsByClassName('form-summary-container')[0];
            if (htmlBody != null) {
                htmlBody = htmlBody.outerHTML + this.constructLaunchInWayfindLink();
                EDChestPainExportToECPP.performExport(this.props.workflows[this.props.pathwayId], htmlBody);
            }
        }
    }

    getReadableDate(workflow, identifier, format, isDefaultStyle, isMandatory) {
        let date = workflow.rawData[identifier];
        if (date == null) {
            if(!isMandatory){
                return this.getMissingDetail(identifier);
            }
            return this.getMandatoryDetail(identifier);
        }
        return <label className={'list-detail '}>{this.formatDate(date, format)}</label>;
    }

    formatDate(date, format) {
        if (date == null) { return null; }
        return moment(date).format(format);
    }

    getMissingDetail(identifier) {
        let reference = PathwayReferenceHelper.reference(identifier, QuidelOrthoChestPainPathway2Reference.data);
        return <label className={'not-present list-detail'}>[{reference.title}]</label>;
    }

    getMandatoryDetail(identifier) {
        let reference = PathwayReferenceHelper.reference(identifier, QuidelOrthoChestPainPathway2Reference.data);
        return <label className={'absent list-detail'}>[{reference.title}]</label>;
    }

    generateNotes(workflow) {
        let notesReference = PathwayReferenceHelper.reference('notesSection', QuidelOrthoChestPainPathway2Reference.data);

        let noNotes = notesReference[PathwayReferenceHelper.Type.sectionChildIDs].map((notesSection) => {
            return workflow.rawData[notesSection];
        }).filter(n => n).length == 0;

        let notesList = (
            <div className='detail-list'>
                {noNotes &&
                    <label className='list-detail not-present'>No notes</label>
                }
                {!noNotes &&
                    notesReference[PathwayReferenceHelper.Type.sectionChildIDs].map((notesSection) => {
                        return (
                            workflow.rawData[notesSection] != null &&
                            <span className="detail-list-span-vertical" key={notesSection}>
                                <label className='list-detail'>{workflow.rawData[notesSection]}</label>
                            </span>
                        );
                    })
                }
            </div>
        );

        return notesList;
    }

    getItemsInSection(workflow, identifiers, valueState, tag, openingString, closingString, lineBreak) {
        let filteredItems = [];
        for (let identifier of identifiers) {
            let listReference = PathwayReferenceHelper.reference(identifier, QuidelOrthoChestPainPathway2Reference.data);
            filteredItems = filteredItems.concat(
                listReference[PathwayReferenceHelper.Type.sectionChildIDs].filter((itemID) => {
                    return workflow.rawData[itemID] == valueState;
                })
            );
        }
        filteredItems = filteredItems.filter(function(e) {
            return e !== 'noToAllHighRiskDifferentialDiagnosis' && e !== 'noToAllSTEMI' && e !== 'noToAllAbnormalities';
        });
        if (filteredItems.length == 0) { 
            return ''; 
        }

        return this.formatList(filteredItems, tag, openingString, closingString, lineBreak, workflow);
    }

    getDisparateItemsAsList(workflow, identifiers, valueState, tag, openingString, closingString, lineBreak) {
        let filteredItems = identifiers.filter((itemID) => {
            if (workflow.rawData[itemID + 'Override'] == true) {
                return workflow.rawData[itemID + 'OverrideValue'] == valueState;
            } else {
                return workflow.rawData[itemID] == valueState;
            }
        });

        if (filteredItems.length == 0) { return ''; }

        return this.formatList(filteredItems, tag, openingString, closingString, lineBreak);
    }

    formatList(identifiers, tag, openingString, closingString, lineBreak, workflow) {
        return (<label className={'list-detail ' + tag} key={tag + identifiers[0]}>
            {openingString} {identifiers.map((itemID) => {
                if (!lineBreak) {
                    return (
                        (PathwayReferenceHelper.reference(itemID, QuidelOrthoChestPainPathway2Reference.data).title.includes(',') ? '\'' : '') + PathwayReferenceHelper.reference(itemID, QuidelOrthoChestPainPathway2Reference.data).title + (PathwayReferenceHelper.reference(itemID, QuidelOrthoChestPainPathway2Reference.data).title.includes(',') ? '\'' : '') + (itemID != identifiers[identifiers.length - 1] ? ', ' : '')
                    );
                }
                if(itemID == 'alternativeFinding'){
                    return (
                        <span className="detail-list-span-vertical" key={itemID}>{'Alternative finding: ' + (workflow.rawData['alternativeFindingSpecify'] == null ? '[Alternative Finding]' : workflow.rawData['alternativeFindingSpecify']) } <br /></span>
                    );
                }

                return (
                    <span className="detail-list-span-vertical" key={itemID}>{PathwayReferenceHelper.reference(itemID, QuidelOrthoChestPainPathway2Reference.data).title + (itemID != identifiers[identifiers.length - 1] ? ',' : '')} <br /></span>
                );
            })}{closingString}
        </label>);
    }

    getReadableValue(workflow, identifier, isMandatory) {
        let reference = PathwayReferenceHelper.reference(identifier, QuidelOrthoChestPainPathway2Reference.data);
        let value = workflow.rawData[identifier];
        let unit = reference[PathwayReferenceHelper.Type.textEntryUnitText];
        if (value == null) { 
            if(isMandatory){
                return this.getMandatoryDetail(identifier);
            }
            return this.getMissingDetail(identifier); 
        }

        switch (reference[PathwayReferenceHelper.Type.controlType]) {
            case FormsContentType.ControlType.plain:
            case FormsContentType.ControlType.badge:
            case FormsContentType.ControlType.calculation:
            case FormsContentType.ControlType.action:
            case FormsContentType.ControlType.picker:
            case FormsContentType.ControlType.weblink:
            case FormsContentType.ControlType.textEntry: {
                if(identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin'){
                    if(value < 0.1) {
                        return '<0.1' + (unit == null ? '' : unit);
                    }
                } 
                return value + (unit == null ? '' : unit);
            }
            case FormsContentType.ControlType.radio:
                return value == true ? 'Yes' : 'No';
            case FormsContentType.ControlType.selection:
                if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.explicit) {
                    return value == true ? 'Yes' : 'No';
                } else if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.simple) {
                    let optionReference = PathwayReferenceHelper.reference(value, QuidelOrthoChestPainPathway2Reference.data);
                    return optionReference.title;
                }
        }

        if(isMandatory){
            return this.getMandatoryDetail(identifier);
        }
        return this.getMissingDetail(identifier);
    }

    generateStemi(workflow) {
        let stemiPresent = workflow.rawData['stemiPresent'];

        if (stemiPresent == null) { return <label className={'list-detail absent'}>[STEMI criteria not specified]</label>; }
        if (stemiPresent != null && stemiPresent) {
            return (
                <span className={'detail-list-span-vertical'}>
                    {this.getItemsInSection(workflow, ['stemiCriteriaSection'], true, 'present-items', '', '', true)}
                    {/* {this.getItemsInSection(workflow, ['stemiCriteriaSection'], false, 'not-present', 'Not present: ', '', false)} */}
                    {this.getItemsInSection(workflow, ['stemiCriteriaSection'], null, 'absent', '[Not specified: ', ']', false)}
                </span>
            );
        } else {
            return (<label className={'list-detail minor-detail'}>No STEMI criteria present</label>);
        }
    }

    generateAbnormalities(workflow) {
        let stemiPresent = workflow.rawData['abnormalitiesPresent'];

        if (stemiPresent == null) { return <label className={'list-detail absent'}>[Other abnormalities not specified]</label>; }
        if (stemiPresent != null && stemiPresent) {
            return (
                <span className={'detail-list-span-vertical'}>
                    {this.getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], true, 'present-items', '', '', true)}
                    {this.getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], false, 'not-present', 'Not present: ', '', false)}
                    {this.getItemsInSection(workflow, ['abnormalitiesCriteriaSection'], null, 'absent', '[Not specified: ', ']', false)}
                </span>
            );
        } else {
            return (<label className={'list-detail minor-detail'}>No abnormalities present</label>);
        }
    }

    getCalculationBadge(workflow, calculationType, identifier) {
        let error = EDChestPainCalculationHelper.calcualtionError(identifier, workflow.rawData, this.state.mainForm);
        
        if(error != null){
            return <label className='list-detail' style={{color: FormsStyles.orange}}>{error.message}</label>;
        }

        if(this.state.progress == 0 && this.state.total == 0){
            return this.getMandatoryDetail(identifier);
        }

        if(((calculationType == CalculationModel.CalculationType.riskCategory) && (this.state.complete / this.state.total) != 1)){
            return this.getMandatoryDetail(identifier);
        }

        let calculation = EDChestPainCalculationHelper.getLatestCalculation(calculationType, this.props.calculations[this.props.pathwayId]);
        if (calculation == null) { return this.getMandatoryDetail(identifier); }

        let badgeStyle = { 
            backgroundColor: calculation.badgeColor == null ? FormsStyles.backgroundColor : calculation.badgeColor,
            color: calculation.badgeColor == null ? 'black' : 'white',
        };

        return <label className='tag' style={badgeStyle}>{calculation.valueString}</label>;
    }

    getAction(action) {
        let title = action.title == null ? PathwayReferenceHelper.reference(action.identifier, QuidelOrthoChestPainPathway2Reference.data).title : action.title;
        let detail = action.detail == null ? PathwayReferenceHelper.reference(action.identifier, QuidelOrthoChestPainPathway2Reference.data).detail : action.detail;
        title = this.stripSpecialChars(title);
        detail = this.stripSpecialChars(detail);
        return (
            <span key={action.uuid} className='detail-list-span-vertical'>
                <label className='list-title'>{title}</label>
                <label className='list-detail'>{detail}</label>
            </span>
        );
    }

    stripSpecialChars(string){
        if (string != null) {
            if(!string.includes('#@')){ return string; } 
            let components = string.split('#@');
            let strippedString = '';
            for(let component of components){
                if(component.includes('^')){
                    let subComponents = component.split('^');
                    strippedString += subComponents[1];
                } else {
                    strippedString += component;
                }
            }
            return strippedString;
        }
    }

    getActions(){
        let sections = [
            'actions',
            'assessmentTeam',
            'referralDisposition',
            'resultsInvestigations',
            
        ];

        return (
            <span className="detail-list-span-vertical">
                {
                    sections.map((section) => {
                        let actionsForSections = PathwayReferenceHelper.reference(section, QuidelOrthoChestPainPathway2Reference.data)[PathwayReferenceHelper.Type.sectionChildIDs];
                        
                        return (
                            actionsForSections.map((childID) => { return this.state.actions.filter((action) => {return action.identifier == childID;}).length > 0 ? true : null; }).filter(n => n).length > 0 &&
                            <div key ={section} style={{marginBottom: sections[sections.length - 1] == section ? '0' : '1em'}}>
                                <div className="actions-list-title">{PathwayReferenceHelper.reference(section, QuidelOrthoChestPainPathway2Reference.data).title}</div>
                                {actionsForSections.map((actionIdentifier) => {
                                    let filteredActions = this.state.actions.filter((action) => {return action.identifier == actionIdentifier;});
                                    if(filteredActions.length > 0){
                                        let action = filteredActions[0];
            
                                        let title = action.title == null ? PathwayReferenceHelper.reference(action.identifier, QuidelOrthoChestPainPathway2Reference.data).title : action.title;
                                        let detail = action.detail == null ? PathwayReferenceHelper.reference(action.identifier, QuidelOrthoChestPainPathway2Reference.data).detail : action.detail;

                                        title = this.stripSpecialChars(title);
                                        detail = this.stripSpecialChars(detail);
                                        return <span key={action.uuid} className='detail-list-span-vertical'>
                                            <label className='list-title'>{title != null ? title.replace('#@', '').replace('^', '') : title}</label>
                                            <label className='list-detail'>{detail != null ? detail.replace('#@', '').replace('^', '') : detail}</label>
                                        </span>;
                                    }
                                    return null;
                                })}
                            </div>
                        );
                    })
                }
            </span>
        );
    }

    getRedFlagsBadge(workflow) {
        let totalRedFlags = workflow.rawData['totalRedFlags'];
        if (totalRedFlags != null && totalRedFlags > 0) {
            return (<label className='tag' style={{ backgroundColor: 'red' }}>{totalRedFlags + ' RED FLAG' + (totalRedFlags == 1 ? '' : 'S')}</label>);
        }
        return ('');
    }

    getRedFlagOverrideReasons(workflow) {
        let redflags = [
            'aorticDissectionPresentRedFlag',
            'oesophagealRuptureRedFlag',
            'pulmonaryEmbolismRedFlag',
            'pancreatitisRedFlag',
            'pneumothoraxRedFlag',
            'newIschaemicChanges',
            'redFlagsOngoingChestPain',
            'crescendoAngina',
            'haemodynamicInstability'
        ];

        let overrideValues = redflags.filter((overrideID) => {
            if (workflow.rawData[overrideID + 'Override'] == true) {
                return true;
            }
            return null;
        }).filter(n => n);

        return (
            overrideValues.length > 0 &&
            <span className='detail-list-span-vertical'>

                <label className='list-title'>{'Red Flag Override Reasons'}</label>
                {overrideValues.map((redFlag) => {
                    return (<label key={redFlag} className='list-detail'>{PathwayReferenceHelper.reference(redFlag, QuidelOrthoChestPainPathway2Reference.data).title + ': '} {(workflow.rawData[redFlag + 'OverrideReason'] != null ? workflow.rawData[redFlag + 'OverrideReason'] : this.getMissingDetail(redFlag + 'OverrideReason'))}</label>);
                })}

            </span>
        );
    }

    areRedFlagPresents(workflow) {
        let redflags = [
            'aorticDissectionPresentRedFlag',
            'oesophagealRuptureRedFlag',
            'pulmonaryEmbolismRedFlag',
            'pancreatitisRedFlag',
            'pneumothoraxRedFlag',
            'newIschaemicChanges',
            'redFlagsOngoingChestPain',
            'crescendoAngina',
            'haemodynamicInstability'
        ];

        return redflags.map((redFlag) => {
            if (workflow.rawData[redFlag + 'Override'] == true) {
                return workflow.rawData[redFlag + 'OverrideValue'] != null ? true : null;
            } else {
                return workflow.rawData[redFlag] != null ? true : null;
            }
        }).filter(n => n).length > 0;

    }

    sectionContainsValue(workflow, values, identifiers){
        return identifiers.map((identifier) => {
            if (workflow.rawData[identifier + 'Override'] == true) {
                return values.includes(workflow.rawData[identifier + 'OverrideValue']);
            }
            return values.includes(workflow.rawData[identifier]);
        }).includes(true);
    }

    getEWSBadge(workflow, identifier){
        let value = workflow.rawData[identifier];
        let ews = calculateEWS(identifier + 'EWS', value);

        if(ews != null && ews.type != EWSCategory.Type.white){
            return (<label className='tag' style={{ backgroundColor: ews.color }}>{ews.score}</label>);
        }
        return null;
    }

    

    getTotalEWSBadge(workflow){
        let identifiers = [
            'heartRateEWS',
            'bloodPressureEWS',
            'bodyTemperatureEWS',
            'oxygenSaturationEWS',
            'respiratoryRateEWS',
            'supplementalOxygenEWS',
            'levelOfConsciousnessEWS'
        ];
        let categories = identifiers.map((identifier) => {
            let value = workflow.rawData[identifier.replace('EWS', '')];
            return calculateEWS(identifier, value);
        }).filter(n => n);

        // Only display an EWS if all categories are represented.
        if (categories.length < identifiers.length) {
            return null;
        }

        let declaredNormal = workflow.rawData['areSignsNormal'];
        let totalEWS = new TotalEWS(categories, declaredNormal);
        if (totalEWS != null) {
            return (<label className='tag' style={{ backgroundColor: totalEWS.solid ? totalEWS.color : FormsStyles.backgroundColor, color:  totalEWS.solid ? 'white' : totalEWS.color }}>{totalEWS.title}</label>);
        }
        return null;
    }

    getVitals(workflow) {
        var identifiers = [
            'heartRate',
            'bloodPressure',
            'bodyTemperature',
            'oxygenSaturation',
            'respiratoryRate',
            'supplementalOxygen',
            'levelOfConsciousness'
        ];

        let isMandatory = workflow.rawData['areSignsNormal'] != true;

        let values = identifiers.map((identifier) => {
            switch(identifier){
                case 'heartRate':
                    return <span key={identifier} style={{marginRight: '0.5em'}}>HR: {this.getReadableValue(workflow, identifier, isMandatory)},</span>;
                case 'bloodPressure':
                    return <span key={identifier} style={{marginRight: '0.5em'}}>BP:  {this.getReadableValue(workflow, identifier, isMandatory)},</span>; 
                case 'bodyTemperature':
                    return <span key={identifier} style={{marginRight: '0.5em'}}>T: {this.getReadableValue(workflow, identifier, isMandatory)},</span>;
                case 'oxygenSaturation':
                    return <span key={identifier} style={{marginRight: '0.5em'}}>SpO₂: {this.getReadableValue(workflow, identifier, isMandatory)},</span>;
                case 'respiratoryRate':
                    return <span key={identifier} style={{marginRight: '0.5em'}}>RR: {this.getReadableValue(workflow, identifier, isMandatory)},</span>;
                case 'supplementalOxygen':
                    return <span key={identifier} style={{marginRight: '0.5em'}}>Supplemental oxygen: {this.getReadableValue(workflow, identifier, isMandatory)},</span>;
                case 'levelOfConsciousness':
                    return <span key={identifier}>Level of consciousness: {this.getReadableValue(workflow, identifier, isMandatory)}</span>;
            }
        });

        return <span> {values.map((vital) => {
            return vital;
        })} </span>;
    }

    getBadgeValue(identifier, workflow){
       
        switch (identifier) {
            case 'ecgTest': {
                var badges = [];

                let stemiPresentValue = workflow.rawData['stemiPresent'];
                if (stemiPresentValue != null && stemiPresentValue) {
                    badges.push(<label key={'ecg-stemi'} className='tag' style={{ backgroundColor: FormsStyles.red, color: 'white' }}>STEMI</label>);
                }

                let abnormalitiesPresentValue = workflow.rawData['abnormalitiesPresent'];
                if (abnormalitiesPresentValue != null && abnormalitiesPresentValue) {
                    badges.push(<label key={'ecg-abnormal'} className='tag' style={{ backgroundColor: FormsStyles.orange, color: 'white' }}>ABNORMAL</label>);
                }

                if (badges.length == 0) {
                    badges.push(<label key={'ecg-normal'} className='tag' style={{ backgroundColor: FormsStyles.backgroundColor, color: FormsStyles.grey }}>NORMAL</label>);
                }

                return <span> {badges.map((badge) => {return badge;})} </span>;
            }

            case 'bloods': {
                let initialTroponinDateAndTime = workflow.rawData['initialBloodsDateAndTime'];
                let initialTroponinValue = workflow.rawData['initialBloodsTroponin'];
                let repeatTroponinValue = workflow.rawData['followUpBloodsTroponin'];
                let repeatTroponinDateAndTime = workflow.rawData['followUpBloodsDateAndTime'];

                let initialTroponinComplete = initialTroponinValue != null && initialTroponinValue != undefined && initialTroponinDateAndTime != null && initialTroponinDateAndTime != undefined;
                let repeatTroponinComplete = repeatTroponinValue != null && repeatTroponinValue != undefined && repeatTroponinDateAndTime != null && repeatTroponinDateAndTime != undefined;
                
                let initialTroponinResult;
                let initialTroponinStatus;
                let repeatTroponinResult;
                let repeatTroponinStatus;
                if (initialTroponinComplete == true) {
                    initialTroponinResult = QuidelOrtho2TroponinResult.newTroponinResult(initialTroponinValue);
                    initialTroponinStatus = initialTroponinResult.getStatus();
                }
                if (repeatTroponinComplete == true) {
                    repeatTroponinResult = QuidelOrtho2TroponinResult.newTroponinResult(repeatTroponinValue);
                    repeatTroponinStatus = repeatTroponinResult.getStatus();
                }

                let badges = [];
                if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.aboveCutoff || repeatTroponinStatus == QuidelOrtho2TroponinResult.Status.aboveCutoff) {
                    //Display above cutoff
                    badges.push(<label key={'bloods-above-cutoff'} className='tag' style={{ backgroundColor: FormsStyles.red, color: 'white' }}>{'ABOVE CUT-OFF'}</label>);
                }
                
                // if (initialTroponinComplete == true && repeatTroponinComplete == true && (Math.abs(initialTroponinResult - repeatTroponinResult) >= 4)) {
                //     //Display delta high
                //     badges.push(<label key={'bloods-delta-high'} className='tag' style={{ backgroundColor: FormsStyles.red, color: 'white' }}>{'DELTA HIGH'}</label>);
                // }

                if (badges.length > 0) {
                    return badges;
                } else if (initialTroponinResult && repeatTroponinResult) {
                    const initialValue = initialTroponinResult.numberValue;
                    const repeatValue = repeatTroponinResult.numberValue;
                    const delta = Math.abs(initialValue - repeatValue);
                        
                    if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.highRisk || repeatTroponinStatus == QuidelOrtho2TroponinResult.Status.highRisk) {
                        return <label key={'bloods-high-rick'} className='tag' style={{ backgroundColor: FormsStyles.red, color: 'white'}}>{'HIGH RISK'}</label>;
                    } else if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.lowRisk && repeatTroponinStatus == QuidelOrtho2TroponinResult.Status.lowRisk) {
                        return <label key={'bloods-normal'} className='tag' style={{ backgroundColor: FormsStyles.backgroundColor, color: FormsStyles.grey}}>{'LOW RISK'}</label>;
                    } else if ((initialTroponinStatus == QuidelOrtho2TroponinResult.Status.lowRisk || repeatTroponinStatus == QuidelOrtho2TroponinResult.Status.lowRisk) && delta < 3) {
                        return <label key={'bloods-normal'} className='tag' style={{ backgroundColor: FormsStyles.backgroundColor, color: FormsStyles.grey}}>{'LOW RISK'}</label>;
                    } else if (delta >= 8) {
                        return <label key={'bloods-high-rick'} className='tag' style={{ backgroundColor: FormsStyles.red, color: 'white'}}>{'HIGH RISK'}</label>;
                    } else {
                        return <label key={'bloods-high-rick'} className='tag' style={{ backgroundColor: FormsStyles.orange, color: 'white'}}>{'INTERMEDIATE RISK'}</label>;
                    }
                }
            }
        }
    }

    getSummary(workflow) {
        return (
            <div ref={node => this.node = node} className='form-summary-container form-page-container'>
                <div className='form-summary' ref={node => this.node = node}>
                    <div id='cardiac-chestpain'>

                        <PrintHeader forceVisibility={true} pathwayId={this.props.pathwayId}/> 

                        <div id='patient-details' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Patient</h2>
                            <div className='detail-list'>
                                {ENABLED_EXPORTS.includes('ecpp') &&
                                    <span className='detail-list-span-vertical'>
                                        <label className='list-title'>NHI Number</label>
                                        <label className='list-detail'>{this.getReadableValue(workflow, 'patientNHINumber', true)}</label>
                                    </span>
                                }

                                <label className='list-title'>Sex</label>
                                <label className='list-detail'>{this.getReadableValue(workflow, 'patientSex', true)}</label>

                                <label className='list-title'>Age</label>
                                {workflow.rawData['patientAge'] == null &&
                                    <label className={'absent list-detail'}>[Age]</label>
                                }
                                {workflow.rawData['patientAge'] != null &&
                                    <label className='list-detail'>{Math.floor(workflow.rawData['patientAge']) + 'yrs'}</label>
                                }
                            </div>
                        </div>

                        <div id='notes' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Notes</h2>
                            {this.generateNotes(workflow)}
                        </div>

                        <div id='structured-assesment' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>EDACS assessment</h2>
                            <div className='detail-list'>
                                <label className='list-title'>Timing</label>
                                <label className='list-detail'>
                                    Pain onset {this.getReadableDate(workflow, 'firstDateTime', 'ddd DD-MMM-YYYY, HH:mm ', false, true)}, lasting {this.getReadableValue(workflow,'firstDuration', true)}
                                </label>

                                <label className='list-title'>{'Symptoms and signs'}</label>
                                {this.getItemsInSection(workflow, ['symptomsAndSigns'], true, 'present-items', '', '', true)}
                                {this.getItemsInSection(workflow, ['symptomsAndSigns'], false, 'not-present', 'Not present: ', '', false)}
                                {this.getItemsInSection(workflow, ['symptomsAndSigns'], null, 'absent', '[Not specified: ', ']', false)}

                                <label className='list-title'>{'Key historical Information'}</label>
                                {this.getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], true, 'present-items', '', '', true)}
                                {this.getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], false, 'not-present', 'Not present: ', '', false)}
                                {this.getItemsInSection(workflow, ['problemsAndPast', 'familyHistory', 'medications'], null, 'absent', '[Not specified: ', ']', false)}
                            </div>
                        </div>

                        <div id='vitals' className='summary-section avoidPageBreak'>
                            <span className={'detail-list-span-horizontal'}><h2 className='detail-list-title'>Vitals</h2> {this.getTotalEWSBadge(workflow)}</span>
                            <div className='detail-list'>
                                {this.getVitals(workflow)}
                            </div>
                        </div>

                        <div id='investigations' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Investigations</h2>
                            <div className='detail-list'>
                                <span className='detail-list-span-horizontal'> <label className='actions-list-title'>{'ECG'}</label>{this.getBadgeValue('ecgTest', workflow)}</span>
                                <label className='list-title'>{'STEMI criteria'}</label>
                                {this.generateStemi(workflow)}
                                <label className='list-title'>{'Other abnormalities'}</label>
                                {this.generateAbnormalities(workflow)}

                                <span className='detail-list-span-horizontal'>
                                    <label className='actions-list-title'>{'Bloods'}</label>
                                    {this.getBadgeValue('bloods', workflow)}
                                </span>
                                <span className="detail-list-span-vertical">
                                    <label className='list-title'>{'Troponin I - Initial'}</label>
                                    <span className='detail-list-span-horizontal'>
                                        {this.getReadableDate(workflow, 'initialBloodsDateAndTime', 'HH:mm ddd DD-MM-YYYY', true, true)}
                                        <label>, {this.getReadableValue(workflow,'initialBloodsTroponin', true)}</label>
                                    </span>
                                </span>

                                <span className="detail-list-span-vertical">
                                    <label className='list-title'>{'Troponin I - Repeat'}</label>
                                    <span className='detail-list-span-horizontal'>
                                        {this.getReadableDate(workflow, 'followUpBloodsDateAndTime', 'HH:mm ddd DD-MM-YYYY', true)}
                                        <label>, {this.getReadableValue(workflow,'followUpBloodsTroponin', false)}</label>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div id='red-flags' className='summary-section avoidPageBreak'>
                            <span className='detail-list-span-horizontal-center'><h2 className='detail-list-title'>Red flags</h2>{this.getRedFlagsBadge(workflow)}</span>
                            <div className='detail-list'>
                                <span className={'detail-list-span-vertical'}>
                                    {this.areRedFlagPresents(workflow) && 
                                        <span className='detail-list-span-vertical'> 
                                            {this.sectionContainsValue(workflow, [true, null, undefined], ['aorticDissectionPresentRedFlag', 'oesophagealRuptureRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pneumothoraxRedFlag']) &&
                                                <span className='detail-list-span-vertical'>
                                                    <label className='list-title'>{'High-risk differential diagnoses'}</label>
                                                    {this.getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'oesophagealRuptureRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pneumothoraxRedFlag'], true, 'present-items', 'High clinical suspicion of: ', '', true)}
                                                    {this.getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'oesophagealRuptureRedFlag', 'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pneumothoraxRedFlag'], null, 'absent', '[Not specified: ', ']', false)}
                                                </span>
                                            }
                                            {this.sectionContainsValue(workflow, [true, null, undefined], ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability', 'newIschaemicChanges']) &&
                                                <span className='detail-list-span-vertical'>
                                                    <label className='list-title'>{'High-risk findings'}</label>
                                                    {this.getDisparateItemsAsList(workflow, ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability', 'newIschaemicChanges'], true, 'present-items', 'High clinical suspicion of: ', '', true)}
                                                    {this.getDisparateItemsAsList(workflow, ['redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability', 'newIschaemicChanges'], null, 'absent', '[Not specified: ', ']', false)}
                                                </span>
                                            }
                                            {this.getDisparateItemsAsList(workflow, ['aorticDissectionPresentRedFlag', 'oesophagealRuptureRedFlag',  'pulmonaryEmbolismRedFlag', 'pancreatitisRedFlag', 'pneumothoraxRedFlag', 'redFlagsOngoingChestPain', 'crescendoAngina', 'haemodynamicInstability', 'newIschaemicChanges'], false, 'not-present', 'Low clinical suspicion: ', '', false)}
                                            {this.getRedFlagOverrideReasons(workflow)}
                                        </span>
                                    }
                                    {!this.areRedFlagPresents(workflow) && <label className='list-detail not-present'>No red flags</label>}
                                </span>
                            </div>
                        </div>

                        <div id='insights' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Insights</h2>
                            <div className='detail-list'>
                                <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'Calculated EDACS'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.edacs, 'calculatedEDACS')}
                                </span>

                                <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'Pathway Risk Category'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.riskCategory, 'pathwayRiskCategory')}
                                </span>
                            </div>
                        </div>

                        <div id='actions' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Actions</h2>
                            <div className='detail-list'>
                                <span className='detail-list-span-vertical'>
                                    {this.state.actions.length > 0 &&
                                        this.getActions()
                                    }
                                    {this.state.actions.length == 0 && <label className='list-detail not-present'>No actions</label>}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    constructLaunchInWayfindLink() {
        let dataString = JSON.stringify(this.props.workflows[this.props.pathwayId]);
        let dataObjectBase64 = Base64.encode(dataString);
        return ReactDOMServer.renderToStaticMarkup( <a href={window.location.origin + '/' + this.props.pathwayId + '/handover?data=' + dataObjectBase64} className="open-in-wayfind-button">Open in Wayfind</a>);
    }

    render() {
        let params = new URLSearchParams(window.location.search);
        let exportType = params.get('type');
        let workflow = this.props.workflows[this.props.pathwayId];
        let formSummaryFormat;
        if(workflow != null){
            if (exportType == 'JSON' || exportType == 'json') {
                formSummaryFormat = <div>{JSON.stringify(this.state.workflow.rawData)}</div>;
            } else {
                formSummaryFormat = this.getSummary(workflow);
            }
        }

        return (
            <div>
                {this.state.exportHCSVisible && <ExportToHCSOverlay formLoader={this.props.formLoader}/>}
                {formSummaryFormat}
            </div>
        );
    }
}

QuidelOrthoChestPain2Summary.propTypes = {
    workflows: PropTypes.object.isRequired,
    calculations: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    pathwayId: PropTypes.string.isRequired,
    newResultsAvailable: PropTypes.bool.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    const { calculations } = state.calculationReducer;
    const { newResultsAvailable } = state.notificationsReducer;
    const { actions } = state.actionsReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed, calculations, newResultsAvailable, actions};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({}, dispatch)
);

const hoc =connect(mapStateToProps, mapDispatchToProps)(QuidelOrthoChestPain2Summary);

// EXPORT COMPONENT

export { hoc as QuidelOrthoChestPain2Summary };