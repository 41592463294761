import { enqueueSnackbar } from "notistack";
import { redux } from "../../../App";

import { ActionModel } from "../../../forms/Shared Custom Types/ActionModel";
import {
  postResultsAlert,
  postResultsBadge,
  removeResultsAlertValue,
} from "../actions/NotificationsActions";
//import { DependantValuesUpdaterRouter } from '../../../forms/DependantValuesUpdaterRouter';

export const SAVE_ACTION_PENDING = "SAVE_ACTION_PENDING";
export const SAVE_ACTION_FULFILLED = "SAVE_ACTION_FULFILLED";
export const SAVE_ACTION_FAILED = "SAVE_ACTION_FAILED";
export const CLEAR_ACTIONS_FULFILLED = "CLEAR_ACTIONS_FULFILLED";

export const LAST_OBSERVED_ACTIONS_UPDATED = "LAST_OBSERVED_ACTIONS_UPDATED";
export const CLEAR_OBSERVED_ACTIONS = "CLEAR_OBSERVED_ACTIONS";

export const UPDATE_ACTIONS_FULFILLED = "UPDATE_ACTIONS_FULFILLED";

const saveAction = (pathwayId, actions) => (dispatch) => {
  dispatch({
    type: SAVE_ACTION_PENDING,
  });

  dispatch({
    type: SAVE_ACTION_FULFILLED,
    payload: {
      pathwayId: pathwayId,
      actions: actions,
    },
  });
};

const snapshotObservedActions = (pathwayId, actions) => (dispatch) => {
  dispatch({
    type: LAST_OBSERVED_ACTIONS_UPDATED,
    payload: {
      pathwayId: pathwayId,
      lastObservedActions: actions,
    },
  });
};

const clearSnapshotActions = (pathwayId) => (dispatch) => {
  dispatch({
    type: CLEAR_OBSERVED_ACTIONS,
    payload: {
      pathwayId: pathwayId,
      actions: [],
    },
  });
};

const updateActions = (pathwayId, workflow, form) => (dispatch) => {
  let oldActions = {};
  if (redux.store.getState().actionsReducer.actions[pathwayId] != null) {
    oldActions = JSON.parse(
      JSON.stringify(redux.store.getState().actionsReducer.actions[pathwayId])
    );
  }

  // Refer to mainForm or the parent of the form (mainForm)
  let mainForm = form.parent != null ? form.parent : form;

  let requiredActions = mainForm.actionUpdater.update(pathwayId, workflow, oldActions, form);
  //let requiredActions = DependantValuesUpdaterRouter.updateActions(pathwayId, workflow, oldActions);

  redux.store.dispatch(saveAction(pathwayId, requiredActions));

  let lastViewedActions = [];
  if (redux.store.getState().actionsReducer.lastObservedActions[pathwayId] != null) {
    lastViewedActions = JSON.parse(
      JSON.stringify(redux.store.getState().actionsReducer.lastObservedActions[pathwayId])
    );
  }

  let unobservedActions = requiredActions.filter((action) => {
    return !action.observed;
  });
  if (
    unobservedActions.length > 0 &&
    actionsDifferFromlastObservation(requiredActions, lastViewedActions)
  ) {
    let containsNewActionsFromPreviousAlert = true;

    let newUniqueActions = unobservedActions.filter((action) => {
      let exisitngOldActions = oldActions.filter((oldAction) => {
        return (
          ActionModel.rehydrate(oldAction).uniqueModelUuid() ==
          ActionModel.rehydrate(action).uniqueModelUuid()
        );
      });
      return exisitngOldActions.length == 0;
    });

    let actionsToBeRemoved = unobservedActions.filter((action) => {
      return action.removeAfterObservation;
    });

    containsNewActionsFromPreviousAlert =
      newUniqueActions.length > 0 || actionsToBeRemoved.length > 0;

    if (containsNewActionsFromPreviousAlert) {
      if (pathwayId == "chestPain01") {
        redux.store.dispatch(postResultsAlert(pathwayId, ["Actions"]));
      } else if (pathwayId == "accbist01") {
        redux.store.dispatch(postResultsAlert(pathwayId, ["Actions"]));
      }
      redux.store.dispatch(postResultsBadge(pathwayId));
    }
  } else if (workflow.rawData["lossOfConsciousnessDuration"] >= 30 && pathwayId === "accbist01") {
    enqueueSnackbar(
      "A loss of consciousness over 30 minutes indicates a severe head injury and exceeds the thresholds for a Mild Traumatic Brain Injury. Please contact a specialist for further guidance.",
      {
        persist: true,
        variant: "warning",
      }
    );
    //redux.store.dispatch(postResultsAlert(pathwayId, ['Actions']));
    redux.store.dispatch(postResultsBadge(pathwayId));
  } else {
    redux.store.dispatch(removeResultsAlertValue(pathwayId, "Actions"));
  }

  dispatch({
    type: UPDATE_ACTIONS_FULFILLED,
  });
};

const clearActions = (pathwayId) => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIONS_FULFILLED,
    payload: {
      pathwayId: pathwayId,
      actions: [],
    },
  });
};

const checkAndUpdateForAction = (
  pathwayId,
  proposedActionRequired,
  proposedAction,
  identifier,
  actions
) => {
  let existingAction;
  if (actions != null && Array.isArray(actions)) {
    let filteredActions = actions.filter((action) => {
      return action.identifier == identifier;
    });
    if (filteredActions.length != 0) {
      existingAction = ActionModel.rehydrate(filteredActions[0]);
    }
  }

  let resultingAction;
  // Determine if we're generating this action for the first time
  if (existingAction != null) {
    let existingUUID = existingAction.uniqueModelUuid();
    if (proposedAction != null && existingUUID != proposedAction.uniqueModelUuid()) {
      existingAction.overwrite(proposedAction);
      existingAction.observed = false;
      existingAction.removeAfterObservation = proposedActionRequired == false;
    } else if (proposedActionRequired && existingAction.removeAfterObservation == true) {
      existingAction.observed = false;
      existingAction.removeAfterObservation = false;
    } else if (!proposedActionRequired && existingAction.removeAfterObservation == false) {
      if (!existingAction.observed) {
        return null;
      }
      existingAction.observed = false;
      existingAction.removeAfterObservation = true;
    }
    resultingAction = existingAction;
  } else if (proposedActionRequired && proposedAction != null) {
    resultingAction = proposedAction;
  } else {
    return null;
  }

  return resultingAction;
};

function actionsDifferFromlastObservation(newActions, lastObservedActions) {
  if (newActions.length !== lastObservedActions.length) {
    return true;
  }

  for (var i = newActions.length; i--; ) {
    let newAction = ActionModel.rehydrate(newActions[0]);

    let noPreviousAction =
      lastObservedActions.filter((item) => {
        let oldAction = ActionModel.rehydrate(item);
        return (
          newAction.uniqueModelUuid() == oldAction.uniqueModelUuid() &&
          !oldAction.removeAfterObservation &&
          newAction.detail != null
        );
      }).length == 0;

    if (noPreviousAction) {
      return true;
    }
  }

  return false;
}

export {
  saveAction,
  clearActions,
  updateActions,
  snapshotObservedActions,
  clearSnapshotActions,
  checkAndUpdateForAction,
};
