import React from "react";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Edit, Logout, Telegram } from "@mui/icons-material";
import AvatarImage from "../../images/avatar.png";

interface HeaderUserProfileProp {
  collapseHeader: boolean;
  userName: string;
  userEmail: string;
  userHPINumber: string;
  isLowerAccess: boolean;
}

const HeaderUserProfile: React.FC<HeaderUserProfileProp> = (props) => {
  const { instance } = useMsal();

  const openFeedbackURL = () => {
    window.open(
      "https://otago.au1.qualtrics.com/jfe/form/SV_bJYbLw7JrdwvZzw",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openUpdateWorkforceAccount = () => {
    const params = new URLSearchParams();
    params.append("redirecturl", window.location.origin + "/auth");
    params.append("clientid", process.env.REACT_APP_CLIENT_ID ?? "");
    params.append("levelrequired", "3");

    const url = `${
      process.env.REACT_APP_HEALTH_NZ_UPDATE_ACCOUNT_URL
    }?${params.toString()}`;
    window.open(url, "_self");
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <Box>
      <div className={"header-menu " + (props.collapseHeader ? "docked" : "")}>
        <div className="profile-details-container">
          <div className="image-container">
            <img src={AvatarImage} height={100} width={100} alt="user avatar" />
          </div>
          <div className="details-container">
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
              }}
              variant="body1"
            >
              {props.userName}
            </Typography>
            <Typography
              sx={{
                color: "white",
                textAlign: "left",
              }}
              variant="body2"
            >
              {props.userEmail}
            </Typography>
          </div>
        </div>
        <div className="hpi-identification-container">
          <div className="hpi-label">
            <Typography variant="h6">HPI CPN</Typography>
          </div>
          <div className="hpi-number">
            <Typography variant="body2">{props.userHPINumber}</Typography>
          </div>
          <div className="level-identification-label">
            <Typography variant="h6">Level of identification</Typography>
          </div>
          <div className="level-identification">
            <Typography variant="body2">Basic</Typography>
          </div>
        </div>
        <List>
          {props.isLowerAccess && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => openUpdateWorkforceAccount()}>
                <ListItemIcon>
                  <Edit sx={{ color: "rgba(50, 139, 183, 1)" }} />
                </ListItemIcon>
                <ListItemText primary="Update Workforce Account" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem onClick={() => openFeedbackURL()} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Telegram sx={{ color: "rgba(50, 139, 183, 1)" }} />
              </ListItemIcon>
              <ListItemText primary="Feedback" />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleLogout()} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Logout sx={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText sx={{ color: "red" }} primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </Box>
  );
};

export default HeaderUserProfile;
