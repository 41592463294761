import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Alert, Box, Button, Grid } from "@mui/material";
import WayfindLogo from "../../images/WayfindLogov2.png";
import QuidelOrthoLogo from "../../images/Quidel-logo.svg";
import QuidelOrthoBG from "../../images/Quidel-BG.svg";
import MyHealthButton from "../Common/MyHealthButton";
import {
  QuidelOrthoPathWay2,
  QuidelOrthoPathWay,
  getPathwayIdFromURL,
} from "../../helpers/URLHelper";
import { AuthServerError } from "../routers/AuthHandler";
import { loginRequest } from "../../config/AuthConfig";
import LoadingPage from "./LoadingPage";
import { useNavigate } from "react-router-dom";
interface LoginPageProp {
  updateOption?: boolean;
  authError?: AuthServerError;
}
const LoginPage: React.FC<LoginPageProp> = (prop) => {
  const navigate = useNavigate();

  const { instance, inProgress } = useMsal();
  const [designMode, setDesignMode] = useState<"Wayfind" | "QuidelOrtho">(
    "Wayfind"
  );
  useEffect(() => {
    const pathway = getPathwayIdFromURL();
    if (pathway === QuidelOrthoPathWay || pathway === QuidelOrthoPathWay2) {
      setDesignMode("QuidelOrtho");
    }
  }, []);
  const handleLogin = () => {
    if (prop.updateOption) {
      const params = new URLSearchParams();
      params.append("redirecturl", window.location.origin + "/auth");
      params.append("clientid", process.env.REACT_APP_CLIENT_ID ?? "");
      params.append("levelrequired", "3");
      const url = `${
        process.env.REACT_APP_HEALTH_NZ_UPDATE_ACCOUNT_URL
      }?${params.toString()}`;
      window.open(url, "_self");
    } else {
      instance.loginRedirect(loginRequest);
      navigate("/", { replace: true });
    }
  };
  const handleLogout = () => {
    // Redirect to login page
    instance.logout();
  };
  if (inProgress === "login") {
    return <LoadingPage />;
  } else {
    return (
      <Grid
        container
        sx={{ height: "100%" }}
        spacing={0}
        columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              minHeight: "200px",
              backgroundImage:
                designMode === "QuidelOrtho"
                  ? `url('${QuidelOrthoBG}')`
                  : "linear-gradient(0deg, rgba(43, 190, 98, 1) 0%, rgba(16, 185, 141, 1) 47%, rgba(50, 139, 183, 1) 100%);",
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ width: "300px" }}
              component="img"
              src={designMode === "QuidelOrtho" ? QuidelOrthoLogo : WayfindLogo}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ height: { sm: "auto", md: "100%" } }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {prop.updateOption && (
              <Box sx={{ py: 3, width: "80%" }}>
                <Alert variant="outlined" severity="warning">
                  Your account does not have the necessary permissions to access
                  this feature. Please update your confidence level to meet our
                  requirements.
                </Alert>
              </Box>
            )}
            {prop.authError && (
              <Box sx={{ py: 3, width: "80%" }}>
                <Alert variant="outlined" severity="error">
                  <Box sx={{ color: "red" }}> {prop.authError.error} </Box>
                  <Box> {prop.authError.description} </Box>
                </Alert>
              </Box>
            )}
            <MyHealthButton
              updateOption={prop.updateOption}
              onClick={() => {
                handleLogin();
              }}
            />
            {prop.updateOption && (
              <Button
                sx={{ textTransform: "none", mt: 3 }}
                color="info"
                variant="text"
                onClick={() => handleLogout()}
              >
                Log out
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
};
export default LoginPage;
