import moment from 'moment';
import { EDChestPainBloodsForm } from './EDChestPain-BloodsForm';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { IsDate, IsDateInPastError } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import { IsQuidelOrthoTroponinResult, IsQuidelOrthoTroponinString } from '../Validation Overrides/QuidelOrthoChestPain-TroponinValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { QuidelOrthoChestPain2MainForm } from './QuidelOrthoChestPain2-MainForm';

export class QuidelOrthoChestPain2BloodsForm extends EDChestPainBloodsForm {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    submissionValidation(identifier) {
        if (identifier === 'initialBloodsDateAndTime' || identifier == 'followUpBloodsDateAndTime') {
            return [IsDate, IsDateInPastError];
        } else if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsQuidelOrthoTroponinResult];
        } 
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'initialBloodsTroponin' || identifier == 'followUpBloodsTroponin') {
            return [IsQuidelOrthoTroponinString];
        }

        if (identifier == 'followUpBloodsDateAndTime') {
            let self = this;
            let value = self.getValue(identifier);
            let check1Value = self.getValue('initialBloodsDateAndTime');
            if (value != null && value != undefined && check1Value != null && check1Value != undefined) {
                let firstCheck = moment(new Date(check1Value));
                let secondCheck = moment(new Date(value));
                var duration = moment.duration(secondCheck.diff(firstCheck));
                var mins = duration.asMinutes();

                if (mins < 60) {
                    let valueConflict = new FormValidation('NotPassed1HourValidation', function () {
                        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'The follow-up blood test date and time must be at least 60 minutes after the initial test.')];
                    });

                    return [valueConflict];
                }
                else {
                    return [];
                }
            }
            else {
                return [];
            }
        }
        return [];
    }

    abbreviatedString(identifier, value) {
        switch (identifier) {
            case 'initialBloodsTroponin':
            case 'followUpBloodsTroponin': {
                if(value < 0.1){
                    return 'Troponin ' + '<0.1' + 'ng/L';
                }
                return 'Troponin ' + value + 'ng/L';
            }
            case 'initialBloodsDateAndTime':
            case 'followUpBloodsDateAndTime':
                return value;
        }
    }

    getValueType(identifier) {
        if (identifier === 'initialBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'initialBloodsTroponin') {
            return 'qoTroponin2';
        } else  if (identifier === 'followUpBloodsDateAndTime') {
            return 'date';
        } else if (identifier == 'followUpBloodsTroponin') {
            return 'qoTroponin2';
        }

        return null;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        if (identifier === 'followUpBloodsDateAndTime' || identifier === 'followUpBloodsTroponin') {
            // Hide Repeat section if First troponin < 4, ECG normal and the hours between start to first troponin is less than 3 hour
            let initialTroponinDateAndTime = this.getValue('initialBloodsDateAndTime');
            let initialTroponinValue = this.getValue('initialBloodsTroponin');
            
            const ecgSubform = this.subform('ecgTest');
            let ecgCompletionProgress = ecgSubform.completionProgress();
            let ecgSubformCompletion = (ecgCompletionProgress.current / ecgCompletionProgress.total) == 1;
            const ecgBadged = QuidelOrthoChestPain2MainForm.getECGBadges(ecgSubform, ecgSubformCompletion);
            const isEcgNormal = (ecgBadged.length === 1 && ecgBadged[0].value === 'NORMAL');

            const assessmentFirstDateTime = this.getValue('firstDateTime');
            let firstCheck = moment(new Date(assessmentFirstDateTime));
            let bloodDateTime = moment(new Date(initialTroponinDateAndTime));
            var duration = moment.duration(bloodDateTime.diff(firstCheck));
            var minutesForBloodTest = duration.asMinutes();
            
            if (initialTroponinValue < 4 && isEcgNormal && minutesForBloodTest < 180) {
                return false;
            }
            return true;
        }

        return super.shouldDisplayFormDetailForIdentifier(identifier);
    }
}