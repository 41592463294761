import React, { createContext, ReactNode, useContext } from "react";
import { FormLoader } from "../../forms/FormLoader";
import { FHIRStoreReturn, useFHIRStore } from "../Hooks/useFHIRStore";

interface FormRouteHandlerProp {
  children: ReactNode;
  formLoader: FormLoader;
}

const FHIRStoreContext = createContext<FHIRStoreReturn | undefined>(undefined);

// Provider component
export const FHIRStoreProvider: React.FC<FormRouteHandlerProp> = ({ formLoader, children }) => {
  const store = useFHIRStore(formLoader);
  return <FHIRStoreContext.Provider value={store}>{children}</FHIRStoreContext.Provider>;
};

export const useFHIRStoreContext = (): FHIRStoreReturn => {
  const context = useContext(FHIRStoreContext);
  if (!context) {
    throw new Error("useFHIRStoreContext must be used within an FHIRStoreProvider");
  }
  return context;
};

const FormRouteHandler: React.FC<FormRouteHandlerProp> = ({ children, formLoader }) => {
  return <FHIRStoreProvider formLoader={formLoader}>{children}</FHIRStoreProvider>;
};

export default FormRouteHandler;
