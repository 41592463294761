import { FormWithRedux } from '../../FormWithRedux';
import React from 'react';

export class ACCBISTPupilAssessmentForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater, index) {
        super('pupilAssessmentSubform' + index, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
        this.index = index;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        let allFineCheck = this.getValue('neuroObsOkayCheck' + this.index);
        
        if (allFineCheck != null && allFineCheck != undefined && allFineCheck == true) {
            return false;
        }

        return true;
    }

    getValueType(identifier) {

        if(identifier === 'leftPupilSize' + this.index || identifier === 'rightPupilSize' + this.index) {
            return 'double';
        }
        else if (identifier === 'leftPupilReaction' + this.index || identifier === 'rightPupilReaction' + this.index) {
            return 'string';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    detailForIdentifier(prefix, value) {
        if (value.identifier === 'leftPupilSize' + this.index && value.valueDescription() != null) {
            return prefix + 'Left Size: ' + value.valueDescription() + 'mm';
        }
        else if (value.identifier === 'rightPupilSize' + this.index && value.valueDescription() != null) {
            return prefix + 'Right Size: ' + value.valueDescription() + 'mm';
        }
        else if (value.identifier === 'leftPupilReaction' + this.index && value.valueDescription() != null) {
            return prefix + 'Left Reaction: ' + value.valueDescription();
        }
        else if (value.identifier === 'rightPupilReaction' + this.index && value.valueDescription() != null) {
            return prefix + 'Right Reaction: ' + value.valueDescription();
        }
        else {
            return super.detailForIdentifier(prefix, value);
        }
    }

    htmlForIdentifier(prefix, value) {
        if (value.identifier === 'leftPupilSize' + this.index && value.valueDescription() != null) {
            return (<div key={value.identifier} >
                <label className='list-detail'>{'Left Size: '} &#160;</label>
                <label className='list-detail'>{value.valueDescription()}mm</label>
            </div>);
        }
        else if (value.identifier === 'rightPupilSize' + this.index && value.valueDescription() != null) {
            return (<div key={value.identifier} >
                <label className='list-detail'>{'Right Size: '} &#160;</label>
                <label className='list-detail'>{value.valueDescription()}mm</label>
            </div>);
        }
        else if (value.identifier === 'leftPupilReaction' + this.index && value.valueDescription() != null) {
            return (<div key={value.identifier} >
                <label className='list-detail'>{'Left Reaction: '} &#160;</label>
                <label className='list-detail'>{value.valueDescription()}</label>
            </div>);
        }
        else if (value.identifier === 'rightPupilReaction' + this.index && value.valueDescription() != null) {
            return (<div key={value.identifier} >
                <label className='list-detail'>{'Right Reaction: '} &#160;</label>
                <label className='list-detail'>{value.valueDescription()}</label>
            </div>);
        }
        else {
            return super.htmlForIdentifier(prefix, value);
        }
    }
}