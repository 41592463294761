import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useFHIRStoreContext } from "../FormRouteHandler";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import redux from "../../state/stores/AppStore";
import { clearWorkflow } from "../../state/actions/WorkflowResponseActions";
import {
  clearCalculations,
  clearSnapshotCalculations,
} from "../../state/actions/CalculationActions";
import { clearActions, clearSnapshotActions } from "../../state/actions/ActionActions";
import { clearState } from "../../state/actions/SubformStateActions";
import { clearResultsAlert, clearResultsBadge } from "../../state/actions/NotificationsActions";

interface SubmitToFHIROverlayProp {
  open: boolean;
  handleClose: () => void;
}

const SubmitToFHIROverlay: React.FC<SubmitToFHIROverlayProp> = ({ open, handleClose }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { submitQuestionnaireResponse } = useFHIRStoreContext();
  const navigate = useNavigate();
  const BistPathwayId = "accbist01";

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      // Submit QuestionnaireResponse with completed status (Error handling is in the custom hook)
      const result = await submitQuestionnaireResponse(BistPathwayId);
      if (result) {
        setSubmitted(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onClose = () => {
    if (submitting) return;

    if (submitted) {
      // Clear all forms
      redux.store.dispatch(clearWorkflow(BistPathwayId));
      redux.store.dispatch(clearCalculations(BistPathwayId));
      redux.store.dispatch(clearSnapshotCalculations(BistPathwayId));
      redux.store.dispatch(clearActions(BistPathwayId));
      redux.store.dispatch(clearSnapshotActions(BistPathwayId));
      redux.store.dispatch(clearState(BistPathwayId));
      redux.store.dispatch(clearResultsAlert(BistPathwayId));
      redux.store.dispatch(clearResultsBadge(BistPathwayId));
      redux.store.dispatch(clearWorkflow(BistPathwayId));
      // Close the window
      handleClose();
      // Move to initial page
      navigate("/");
    } else {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontSize="large">Submit Data to FHIR Server</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {submitted ? (
            <Alert severity="success">The form data successfully submitted to FHIR Server.</Alert>
          ) : (
            <Alert severity="info">
              Click the Submit button to store the pathway data on the FHIR server. <br /> Once
              submitted, the data will be securely transferred and integrated with the FHIR system.
              <br />
              <br />
              Please ensure all information is accurate before proceeding.
            </Alert>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{ my: 2, textTransform: "none" }}
            variant="text"
            color="error"
            onClick={onClose}
            disabled={submitting}
          >
            {submitted ? "Close" : "Cancel"}
          </Button>
          {!submitted && (
            <LoadingButton
              sx={{ m: 2, width: "120px", textTransform: "none" }}
              loading={submitting}
              variant="outlined"
              onClick={() => handleSubmit()}
              autoFocus
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitToFHIROverlay;
