import moment from 'moment';
import { FormWithRedux } from '../../FormWithRedux';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { QuidelOrtho2TroponinResult } from '../Custom Types/QuidelOrtho2Troponin';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { redux } from '../../../App';
import { ActionModel } from '../../Shared Custom Types/ActionModel';
import { EDChestPainNotesForm } from './EDChestPain-NotesForm';
import { EDChestPainResultsForm } from './EDChestPain-ResultsForm';
import { EDChestPainHighRiskDifferentialDiagnosisForm } from './EDChestPain-HighRiskDifferentialDiagnosisForm';
import { EDChestPainVitalSignsForm } from './EDChestPain-VitalSignsForm';
import { EDChestPainECGForm } from './EDChestPain-ECGForm';
import { EDChestPainClinicalAssessmentForm } from './EDChestPain-ClinicalAssessmentForm';
import { EDChestPainPatientDetailsForm } from './EDChestPain-PatientDetailsForm';
import { QuidelOrthoChestPain2BloodsForm } from './QuidelOrthoChestPain2-BloodsForm';
import { EDChestPainIntroForm } from './EDChestPain-IntroForm';
import { EDChestPainActions } from '../Calculations/EDChestPain-Actions';
import { EDChestPainDependantValuesUpdater } from '../Calculations/EDChestPain-DependantValuesUpdater';
import { QDChestPainCalculations } from '../Calculations/QuidelOrtho-Calculations';

export class QuidelOrthoChestPain2MainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new EDChestPainActions(formLoader), new EDChestPainDependantValuesUpdater(formLoader), new QDChestPainCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new EDChestPainIntroForm(this.formLoader);
            case 'notesForm':
                return new EDChestPainNotesForm(this.formLoader, this);
            case 'resultsForm':
                return new EDChestPainResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'highRiskDifferentialDiagnosis':
                return new EDChestPainHighRiskDifferentialDiagnosisForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'vitalSigns':
                return new EDChestPainVitalSignsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'ecgTest':
                return new EDChestPainECGForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'initialBloods':
                return new QuidelOrthoChestPain2BloodsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'initialAssessment':
                return new EDChestPainClinicalAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetails':
                return new EDChestPainPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'boolean';
    }

    editingValidation(identifier){
        if (identifier == 'initialBloods'){
            let self = this;
            let scheduledActionPassed = new FormValidation('FollowUpBloodsAction', function () {
                let pathwayActions = redux.store.getState().actionsReducer.actions[self.pathwayId];
                if(pathwayActions != null){
                    let action = pathwayActions.filter((action) => { return action.identifier == 'repeatTroponinAction';})[0];
                    if(action != null && action.scheduledAction != null){
                        let actionModel = new ActionModel(action.pathwayUuid, action.versionUuid, action.identifier, action.title, action.detail, action.priority, action.observed, action.removeAfterObservation, action.lastModifiedDate, action.uuid, action.scheduledAction);

                        let string = actionModel.scheduledActionDetailStringSimple(false);
                        if(string != null){
                            return [new FormValidationError(FormValidationError.FormErrorType.warning, string)];
                        }
                        return [];
                    }
                }
                return [];
            });
                
            return [scheduledActionPassed];
        }

        return [];
    }

    submissionValidation() {
        return [];
    }

    detailString() {
        return 'summary';
    }

    getBadgeValue(identifier) {
        let subform = this.subform(identifier);
        let completionProgress = subform.completionProgress();
        let subformCompletion = (completionProgress.current / completionProgress.total) == 1;

        switch (identifier) {
            case 'vitalSigns': {
                let vitalsProgress = subform.completionProgress();
                if(vitalsProgress.current / vitalsProgress.total == 1){
                    let totalEWS = subform.totalEWS();
                    if (totalEWS != null) {
                        return [{
                            color: totalEWS.color,
                            value: totalEWS.title,
                            solid: totalEWS.solid
                        }];
                    }
                }
                
                return [];
            }
            case 'ecgTest': {
                return QuidelOrthoChestPain2MainForm.getECGBadges(subform, subformCompletion);
            }
            case 'initialBloods': {
                let initialTroponinDateAndTime = this.getValue('initialBloodsDateAndTime');
                let initialTroponinValue = this.getValue('initialBloodsTroponin');
                let repeatTroponinValue = this.getValue('followUpBloodsTroponin');
                let repeatTroponinDateAndTime = this.getValue('followUpBloodsDateAndTime');

                let initialTroponinComplete = initialTroponinValue != null && initialTroponinValue != undefined && initialTroponinDateAndTime != null && initialTroponinDateAndTime != undefined;
                let repeatTroponinComplete = repeatTroponinValue != null && repeatTroponinValue != undefined && repeatTroponinDateAndTime != null && repeatTroponinDateAndTime != undefined;
                
                let initialTroponinResult;
                let initialTroponinStatus;
                let repeatTroponinResult;
                let repeatTroponinStatus;
                if (initialTroponinComplete == true) {
                    initialTroponinResult = QuidelOrtho2TroponinResult.newTroponinResult(initialTroponinValue);
                    initialTroponinStatus = initialTroponinResult.getStatus();
                }
                if (repeatTroponinComplete == true) {
                    repeatTroponinResult = QuidelOrtho2TroponinResult.newTroponinResult(repeatTroponinValue);
                    repeatTroponinStatus = repeatTroponinResult.getStatus();
                }

                let badges = [];
                if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.aboveCutoff || repeatTroponinStatus == QuidelOrtho2TroponinResult.Status.aboveCutoff) {
                    //Display above cutoff
                    badges.push({
                        color: FormsStyles.red,
                        value: 'ABOVE CUT-OFF',
                        solid: true
                    });
                }

                // not required for Quidel
                // if (initialTroponinComplete == true && repeatTroponinComplete == true && (Math.abs(initialTroponinResult - repeatTroponinResult) >= 9999999)) {
                //     //Display delta high
                //     badges.push({
                //         color: FormsStyles.red,
                //         value: 'DELTA HIGH',
                //         solid: true
                //     });
                // } 

                if (badges.length > 0) {
                    return badges;
                } else {
                    // Low Risk if First troponin < 4, ECG normal and the hours between start to first troponin is less than 3 hour
                    const ecgSubform = this.subform('ecgTest');
                    let ecgCompletionProgress = ecgSubform.completionProgress();
                    let ecgSubformCompletion = (ecgCompletionProgress.current / ecgCompletionProgress.total) == 1;
                    const ecgBadged = QuidelOrthoChestPain2MainForm.getECGBadges(ecgSubform, ecgSubformCompletion);
                    const isEcgNormal = (ecgBadged.length === 1 && ecgBadged[0].value === 'NORMAL');

                    const assessmentFirstDateTime = this.getValue('firstDateTime');
                    let firstCheck = moment(new Date(assessmentFirstDateTime));
                    let bloodDateTime = moment(new Date(initialTroponinDateAndTime));
                    var duration = moment.duration(bloodDateTime.diff(firstCheck));
                    var minutesForBloodTest = duration.asMinutes();
                    
                    if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.lowRisk && isEcgNormal && minutesForBloodTest < 180) {
                        return [{
                            color: FormsStyles.grey,
                            value: 'LOW RISK',
                            solid: false
                        }];
                    } else if (initialTroponinResult && repeatTroponinResult) {
                        const initialValue = initialTroponinResult.numberValue;
                        const repeatValue = repeatTroponinResult.numberValue;
                        const delta = Math.abs(initialValue - repeatValue);
                            
                        if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.highRisk || delta >= 8) {
                            //Display HIGH RISK when troponin over 60 or difference is more than or equal 8
                            return [{
                                color: FormsStyles.red,
                                value: 'HIGH RISK',
                                solid: true
                            }];
                        } else if (initialTroponinStatus == QuidelOrtho2TroponinResult.Status.lowRisk || (initialValue < 5 && repeatValue < 3)) {
                            return [{
                                color: FormsStyles.grey,
                                value: 'LOW RISK',
                                solid: false
                            }];
                        } else {
                            return [{
                                color: FormsStyles.orange,
                                value: 'INTERMEDIATE RISK',
                                solid: true
                            }];
                        }
                    }
                }
                
                return [];
            }
        }
    }

    static getECGBadges(subform, subformCompletion) {
        let ecgBadges = [];
        let stemiPresentValue = subform.formDetail('stemiPresent').currentValue;
        if (stemiPresentValue != null && stemiPresentValue && subformCompletion) {
            ecgBadges.push({
                color: FormsStyles.red,
                value: 'STEMI',
                solid: true
            });
        }

        let abnormalitiesPresentValue = subform.formDetail('abnormalitiesPresent').currentValue;
        if (abnormalitiesPresentValue != null && abnormalitiesPresentValue && subformCompletion) {
            ecgBadges.push({
                color: FormsStyles.orange, //FormsStyles.red,
                value: 'ABNORMAL',
                solid: true
            });
        }

        if (ecgBadges.length == 0 && subform.areAllCurrentValuesValid() && subformCompletion) {
            ecgBadges.push({
                color: FormsStyles.grey,
                value: 'NORMAL',
                solid: false
            });
        }
        return ecgBadges;
    }
}