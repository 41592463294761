import { FormWithRedux } from '../../FormWithRedux';
import { DateOfBirth } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import moment from 'moment';
import { DateHelper } from '../../../waferJS/Helpers/DateHelper';

export class ACCBISTPatientDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('patientDetailsSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if(identifier === 'patientSex') {
            if(this.getValue('patientGender') !== null) {
                return false;
            }
        }

        if(identifier == 'patientDOB') {
            return true; //this.getValue(identifier) != null || this.getValue('patientAge') == null;
        }
        else if (identifier == 'patientAgeWarning') {
            let age = this.getValue('patientAge');
            return age != null && age != undefined && age < 16;
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'patientAgeWarning') {
            return false;
        }
        else if (identifier == 'patientDOB') {
            return true; //this.shouldDisplayFormDetailForIdentifier(identifier);
        }
        else if (identifier == 'patientName' 
        || identifier == 'patientAge'
        || identifier == 'patientNHI'
        || identifier == 'patientACCClaimNumber'
        || identifier == 'patientPhoneNumber') {
            return true;
        }

        return false;
    }

    getValueType(identifier) {
        if (identifier == 'patientDOB') {
            return 'date';
        } else if (identifier == 'patientSex' 
        || identifier == 'patientName' 
        || identifier == 'patientNHI' 
        || identifier == 'patientGender' 
        || identifier == 'patientEthnicity'
        || identifier == 'patientAddress'
        || identifier == 'patientNOKName'
        || identifier == 'patientGPName'
        || identifier == 'patientGPPractice'
        || identifier == 'patientNOKPhoneNumber'
        || identifier == 'patientPhoneNumber'
        || identifier == 'patientACCClaimNumber') {
            return 'string';
        } else if(identifier == 'patientAge') {
            return 'double';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'patientDateOfBirth') {
            return [DateOfBirth];
        }
        return [];
    }


    detailForIdentifier(prefix, value) {
        if (value.identifier == 'patientAge' && this.getValue('patientAge') != null){
            if (value.currentValue != null) {
                return 'Age: ' + this.getValue('patientAge') + ' year' + (this.getValue('patientAge') == 1 ? '' : 's');
            }
        }
        else if (value.identifier == 'patientDOB' && this.getValue('patientDOB') != null) {
            let dateValue = moment(this.getValue('patientDOB'));
            return value.title + ': ' + DateHelper.getDateAsFormat(dateValue.toDate(), 'MM-DD-YYYY');
        }
        else {
            return super.detailForIdentifier(prefix, value);
        }
    }
}