
export class QuidelOrtho2TroponinResult {

    static Status = Object.freeze({ aboveCutoff: 1, highRisk: 2, intermediateRisk: 3, lowRisk: 4 });

    statusTitle(status) {
        switch (status) {
            case QuidelOrtho2TroponinResult.Status.aboveCutoff:
                return 'Above cut-off';
            case QuidelOrtho2TroponinResult.Status.highRisk:
                return 'High Risk';
            case QuidelOrtho2TroponinResult.Status.intermediateRisk:
                return 'Intermediate Risk';
            case QuidelOrtho2TroponinResult.Status.lowRisk:
                return 'Low Risk';
        }
    }

    static lessThanThreshold = '<0.1'

    constructor(resultString) {
        if (resultString == QuidelOrtho2TroponinResult.lessThanThreshold) {
            this.numberValue = 0;
        } else {
            let value = parseFloat(resultString);
            if (value < 0.1) {
                this.numberValue = 0;
            } else {
                this.numberValue = value;
            }
        }
    }

    static newTroponinResult(value) {
        if (value != null && value != undefined && value.toString().length > 0 && (value === QuidelOrtho2TroponinResult.lessThanThreshold || (!isNaN(parseFloat(value)) && (parseFloat(value) != null) ))) {
            return new QuidelOrtho2TroponinResult(value.toString());
        }
        return null;
    }

    get string() {
        if (this.numberValue < 0.1) {
            return QuidelOrtho2TroponinResult.lessThanThreshold;
        } else if (this.numberValue != undefined) {
            return this.numberValue.toString();
        }
    }

    set numberValue(value) {
        this._numberValue = value;
    }

    get numberValue() {
        return this._numberValue;
    }

    get doubleValue() {
        if (this.numberValue < 0.1) {
            return 0;
        }
        return this.numberValue;
    }

    getStatus() {
        if (this.doubleValue > 1000) {
            return QuidelOrtho2TroponinResult.Status.aboveCutoff;
        } else {
            if (this.doubleValue > 60) {
                return QuidelOrtho2TroponinResult.Status.highRisk;
            } else if (this.doubleValue < 4) {
                return QuidelOrtho2TroponinResult.Status.lowRisk;
            } else {
                return QuidelOrtho2TroponinResult.Status.intermediateRisk;
            }
        }
    }
}
