/* Get the href for the current page */
export function getURL() {
    return window.location.href;
}

/* Get the pathway ID for the current page */
export function getPathwayIdFromURL() {
    return window.location.href.split('/')[3];
}

/* Get the form ID for the current page */
export function getFormIdFromURL() {
    return window.location.href.split('/')[4];
}

export const QuidelOrthoPathWay = "QuidelOrtho";
export const QuidelOrthoPathWay2 = "QuidelOrtho2";
